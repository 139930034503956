import React, { useState, useEffect, useRef, useCallback } from "react";
import { useEditor } from "@grapesjs/react";
import { authApi } from "../../services/AxiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { chatBoxOpenAI } from "../../actions/chatgpt";
import { addButtonClass, addNewSection, getWebsiteByUserandId, templateSection } from "../../actions/grapesjs";
import Icon from "@mdi/react";
import {
  mdiClose,
  mdiMinus,
  mdiWindowMaximize,
  mdiArrowULeftTop,
} from "@mdi/js";
import { Accordion, Box, AccordionDetails } from "@mui/material";
import "../../pages/chat.css"; // Import your CSS file
import { HexColorPicker, HexColorInput } from "react-colorful";
// import whiteIcon from "../../assets/Icons/whiteIcon.svg";
import { useLocation, useParams } from "react-router-dom";
import {
  findTopLevelAncestor,
  findAndCloneChildComponent,
  findComponentType,
  findCloneComponentByType,
  findAncestor,
  addData,
  removeData,
  mainData,
  reviewData,
  benefitsData,
  workData,
  faqData,
  functionMap,
  addButton,
} from "../constants/jsonData";
import { faqChecked, faqUnChecked, cx } from "./common";
import { ButtonLoaderJoxy } from "./LoaderJoxy";
import CustomAddTagModal from "./CustomAddTagModal";
import CustomEditPagePropModal from "./CustomEditPagePropModal";
import CustomAddDomainModal from "./CustomAddDomainModal";
import CustomUseDomainModal from "./CustomUseDomainModal";
import { buttonClasses, buttonClassesTwo, headerClasses, textClasses, selectedClasses, backgroundClasses, borderClasses } from "../constants/classesData";
var sectionClass;

export default function CustomChatAIManager() {
  const editor = useEditor();
  const dispatch = useDispatch();
  const getSelected = editor.getSelected();
  const { id } = useParams();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  let [chatInputValue, setChatInputValue] = useState("");
  const [showChatBox, setShowChatBox] = useState(true);
  const [textContent, setTextContent] = useState(false);
  const [styleContent, setStyleContent] = useState(false);
  const template = useSelector((state) => state.grapejs);
  const user = useSelector((state) => state.auth);
  const save_template = template?.save_template;
  const templatedId = save_template?._id;
  const websiteColor = save_template?.website_colors;
  const [loader, setLoader] = useState(false);
  const [button, setButton] = useState(false);
  const [colorButton, setColorButton] = useState(false);
  const [brandColor, setBrandColor] = useState(false);
  const [style, setStyle] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpened, setModalOpened] = useState(false);
  const [selectedImageObj, setSelectedImageObj] = useState({});
  const [isStyle, setIsStyle] = useState();
  const [styleData, setStyleData] = useState();
  const [images, setImages] = useState([
    { name: 12434, url: "https://placekitten.com/300/200" },
    { name: 234, url: "https://placekitten.com/400/300" },
    { name: 233, url: "https://placekitten.com/500/400" },
  ]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [visible, setVisible] = useState(false);
  const targetClasses = ["about_us", "benefits", "work","review","service","faq","footer","gallery_1","gallery_2","gallery_3","gallery_4","gallery_5","pricing_1","pricing_2","sponsor-3","sponsor-2","sponsor-1"];
  const CopilotOpenClasses = ["header_second_inner","testimonial_outer","about","header_inner_bg","testimonial","main_logo","header_inner_half","header_mark_icon","header_img_bg","about-image-bg","star_icon","benefits-image-bg","benefits-image-reverse-bg","testimonial_gradient","header_second_inner_bg_gradiant","header_circle_outer_layer","gradiant-square-top","gradiant-square-bottom","header_circle_outer_layer_rev"];
  const [openAddTagModal, setOpenAddTagModal] = useState(false);  
  const [openEditPagePropModal, setOpenEditPagePropModal] = useState(false);
  const [colorOption, setColorOption] = useState(false);
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const [pickerColor, setPickerColor] = useState("");
  const [defaultColor, setDefaultColor] = useState("false");
  const [isTyping, setIsTyping] = useState(false);
  const [minimized, setMinimized] = useState(false);
  const [visibility, setVisibility] = useState(true);
  const [showListOnHover, setShowListOnHover] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [editorElement, setEditorElement] = useState(false);
  const [section, setSection] = useState(false);
  const [selectSection, setSelectSection] = useState(false);
  const [sectionType, setSectionType] = useState("");
  const [selectedTableSection, setSelectedTableSection] = useState(null);
  const [selectedGallerySection, setSelectedGallerySection] = useState(null);
  const [selectedPatnersSection, setSelectedPatnersSection] = useState(null);
  const [sectionIndex, setSectionIndex] = useState("");
  const firstTimeUpdate = useRef("");
  const [selectedBrandColor, setSelectedBrandColor] = useState("");
  const [useDomainModal, setUseDomainModal] = useState(false);
  const [isDNSModalOpen, setIsModalOpen] = useState(false);
  const [publishUrl, setPublishUrl] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [buttonModal, setButtonModal] = useState(false);
  const [buttonAction, setButtonAction] = useState(false);
  const [elementType, setElementType] = useState("");
  const [actionType, setActionType] = useState("");
  const [textype, setTextType] = useState("");
  const [colorApplyType, setColorApplyType] = useState("");
  const [cssApplyStatus, setCssApplyStatus] = useState(false);
  // Function to extract query parameter from the URL
  const getQueryParam = (param) => {
    return new URLSearchParams(location.search).get(param);
  };
  const variantId = getQueryParam("v_id");

  const getStyleProperties = (cssRule) => {
    const properties = [];
    
    // Match class name (e.g., ".className") and CSS properties
    const classMatch = cssRule.match(/\.([a-zA-Z0-9_-]+)\s*\{([^}]+)\}/);
    
    if (classMatch) {
      const className = classMatch[1].trim(); // Extract class name
      const propertiesString = classMatch[2]; // Extract the properties section
  
      // Extract individual properties and values
      const matches = propertiesString.match(/([a-zA-Z-]+)\s*:\s*([^;]+)/g);
      if (matches) {
        matches.forEach((match) => {
          const [_, propertyName, propertyValue] = match.match(
            /([a-zA-Z-]+)\s*:\s*([^;]+)/
          );
          properties.push({
            className, // Include class name in each property object
            property: propertyName.trim(),
            value: propertyValue.trim(),
          });
        });
      }
    }
  
    return properties;
  };

  const keepCurrentColor = () => {
    const css = editor.Css;
    const styleProperties = getStyleProperties(styleData);
    if(defaultColor){
      const rule = css.getRule(`.${styleProperties[0].className}`);
      if(rule){
         // Update the property with the default color
      rule.set('style', {
        ...rule.get('style'),
        [styleProperties[0].property]: defaultColor
      });
        // rule.config.style[styleProperties[0].property] = `${defaultColor}`;
      }
    }else{
      const cssComposer = editor.CssComposer;

      // Find the rule using the selector
      const rule = cssComposer.getRule(`.${styleProperties[0].className}`);
      if (rule) {
        // Remove the rule
        cssComposer.remove(rule);
      }
    }
    // editor.render();
    // editor.refresh();

  }

  const fetchDefaultColor = (styleData) => {
    const styleProperties = getStyleProperties(styleData);
    const css = editor.Css;
    const rule = css.getRule(`.${styleProperties[0].className}`);
    const hasColor = styleProperties[0].property == "color";
    const hasBackgroundColor = styleProperties[0].property == "background-color";
    if(rule){
      if(hasBackgroundColor){
        // change gradiant background color
        const backgroundImage = rule?.attributes?.style['background-image'];
       
        if(backgroundImage){
          setDefaultColor(rule?.attributes?.style['background-image']);
        }else{
          setDefaultColor(rule?.attributes?.style['background-color']);
        }
      }
      if(hasColor){
        // change gradiant text color
        const backgroundImage = rule?.attributes?.style['background-image'];
        const backgroundClip = rule?.attributes?.style['background-clip'];
        
        if(backgroundImage && backgroundClip){
          setDefaultColor(rule?.attributes?.style['background-image']);
        }else{
          setDefaultColor(rule?.attributes?.style['color']);
        }
      }
    }else{
      setDefaultColor("");
    }
  }

  const setColorCss = (styleProperties,color) => {
    const css = editor.Css;
    const rule = css.getRule(`.${styleProperties.className}`);
    const hasColor = styleProperties.property == "color";
    const hasBackgroundColor = styleProperties.property == "background-color";
    const hasButton = styleProperties.property == "button";
    if(rule){
      if(hasBackgroundColor){
        // change gradiant background color
        const backgroundImage = rule?.attributes?.style['background-image'];
       
        if(backgroundImage){
          rule.set('style', {
            ...rule.get('style'),
            'background-image': `linear-gradient(83.96deg, ${color}, ${color})`
          });
        }else{
          rule.set('style', {
            ...rule.get('style'),
            [styleProperties.property]: color
          });
        }
      }
      if(hasColor){
        // change gradiant text color
        const backgroundImage = rule?.attributes?.style['background-image'];
        const backgroundClip = rule?.attributes?.style['background-clip'];
        
        if(backgroundImage && backgroundClip){
          rule.set('style', {
            ...rule.get('style'),
            'background-image': `linear-gradient(83.96deg, ${color}, ${color})`
          }); 
        }else{
          rule.set('style', {
            ...rule.get('style'),
            [styleProperties.property]: color
          });
        }
      }
    }else{
      editor.addStyle(`.${styleProperties.className}{${styleProperties.property}: ${color}}`);
    }
  }

  const PickerDebounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  const callPickerColor = (pickerColor, styleData) => {
    setActionType({ type: "picker", value: {pickerColor:pickerColor, styleData:styleData} });
    const colorData = pickerColor; 
    const css = editor.Css;
    const styleProperties = getStyleProperties(styleData);
    if(styleProperties[0].className){
      setColorCss(styleProperties[0],colorData);
    }
  }

  const debouncedPickerCall = useCallback(PickerDebounce(callPickerColor, 300), []);

  useEffect(() => {
    if (pickerColor && colorPickerVisible) {
      debouncedPickerCall(pickerColor, styleData);
    }
  }, [pickerColor, debouncedPickerCall]);

  //drop down
  const [selectedOption, setSelectedOption] = useState("");

  const [messages, setMessages] = useState([
    {
      message:
        "Hi, what would you like to change please give select any element. ",
      sentTime: "just now",
      sender: "ChatGPT",
    },
  ]);
 

   const handleSelectedSection = (index,sectionType) => {
    if(sectionType === "pricing"){
      setSectionIndex(index);
      setSelectedTableSection(index === selectedTableSection ? null : index);
    }
    if(sectionType === "gallery"){
      setSectionIndex(index);
      setSelectedGallerySection(index === selectedGallerySection ? null : index);
    }
    if(sectionType === "partners"){
      setSectionIndex(index);
      setSelectedPatnersSection(index === selectedPatnersSection ? null : index);
    }
  };

  const TableImages = [
    "https://joxy-template.s3.amazonaws.com/screen_images/Us+Vs+Them.png",
    "https://joxy-template.s3.amazonaws.com/screen_images/Us+Vs+Them(1).png",
  ];

  const GalleryImages = [
    "https://joxy-template.s3.amazonaws.com/screen_images/Our Gallery.png",
    "https://joxy-template.s3.amazonaws.com/screen_images/Our Gallery(1).png",
    "https://joxy-template.s3.amazonaws.com/screen_images/Our Gallery(2).png",
    "https://joxy-template.s3.amazonaws.com/screen_images/Our Gallery(3).png",
  ];

  
  const PatnersImages = [
    "https://joxy-template.s3.amazonaws.com/screen_images/Button.png",
    "https://joxy-template.s3.amazonaws.com/screen_images/Button(1).png",
    "https://joxy-template.s3.amazonaws.com/screen_images/Button(2).png",
  ];



  const handleUndoButtonClick = () => {
    const test = editor.UndoManager.undo();
    if(colorApplyType === "all" || setCssApplyStatus){
      editor.UndoManager.undo();
    }
    // After button click, disable the button
    setButtonDisabled(true);
    setCssApplyStatus(false);
    setColorApplyType("");
  };

  const toggleMinimized = () => {
    setMinimized(!minimized);
  };

  const toggleChatbox = () => {
    setSection(false);
    setVisibility((prevVisibility) => !prevVisibility);
    setMinimized(false);
    
  };

  const closeSectionModal = () => {
    setSection(false);
    setSelectSection(false);
  };

  const API_URL = process.env.REACT_APP_API_URL;

  const token = localStorage.getItem("user");
  const userData = JSON.parse(token);
  const userId = userData.user.id;

  const updateMessage = (newMessage) => {
    if (firstTimeUpdate.current === "" || firstTimeUpdate.current !== newMessage) {
      // setColorButton(false);
      setMessages([
        {
          ...messages[0], // Keep other properties the same
          message: newMessage, // Update message
        },
      ]);
      firstTimeUpdate.current = newMessage;
      setMinimized(false);
      setVisibility(true);
      setShowChatBox(true);
      const classes = getSelected.getClasses();
       // Check if the component includes any of the target classes
       const hasTargetClass = targetClasses.some(cls => classes.includes(cls));
       if ((!hasTargetClass || !user?.section_add_status) && (!hasTargetClass)) {
        setSection(false); 
        dispatch({ type: 'SECTION_ADD_STATUS', payload: false });   
       }else{
        setSection(true);
       }
    }
  };

  const getWordCount = (text) => {
    // Split the string by spaces or other delimiters
    const words = text.trim().split(/\s+/);

    // Filter out empty strings and return the word count
    return words.filter(word => word.length > 0).length;
  };

  useEffect(() => {
    sectionClass = async () => {
      const topLevelAncestor = await findTopLevelAncestor(getSelected);
      const parentClasses = topLevelAncestor.getClasses(); //parent class
      const parentClass = parentClasses[0];
      return parentClass;
    };
    setButton(false);
    if (getSelected) {
      const selectedComponent = window.editor.getSelected()
      if(selectedComponent && selectedComponent.get("type") === "image" ){
       return setShowChatBox(false); 
      }

     if(user?.section_add_status
      ) {
        setSection(true);
      }
       
      // getSelected.trigger('focus');
      setShowListOnHover(true);
      setIsStyle(false);
      setButtonDisabled(false);
      const attributes = getSelected.get("attributes").id;
      if (
        (attributes && getSelected.get("type") !== "image") ||
        (getSelected.get("type") === "image" &&
        getSelected.getClasses().includes("review-quote-img")) ||
        getSelected.get("type") === "text" || CopilotOpenClasses.some(cls => getSelected.getClasses().includes(cls))||
        user?.section_add_status
      ) {
        
        // setSection(true);
        setShowChatBox(true); 
        setVisibility(true);
        setColorPickerVisible(false);
        setEditorElement(true);
        setColorApplyType("");
        setCssApplyStatus(false);
        updateMessage(
          `You selected ${
            attributes ? `${attributes}` : getSelected.cid
          } what would you like me to do?`
        );
      }
      setTextContent(getSelected.getInnerHTML());
      setStyleContent(getSelected.getAttributes());
    } else {
      setTextContent("");
      setStyleContent("");
    }
  }, [getSelected,user]);

  useEffect(()=>{
    dispatch(getWebsiteByUserandId({ id }))
      .then(async (response) => {
        const { data } = response;
        setPublishUrl(data.publish_url);
      })
      .catch((error) => {
        console.log(error);
        // Handle error as needed
      });
  },[])

  const handleSendRequest = async (msg = "") => {
    // if(colorOption){
    //   keepCurrentColor();
    // }
    let message = msg ? msg : chatInputValue;
    let selectedClass = styleContent["class"];
    let selectedContentType = styleContent["type"] ? styleContent["type"] : "text";
    let selectedWordCount = await getWordCount(textContent);
    let selectedSectionClass = await sectionClass();
    // get component by selected section class and get inner html then remove html tags to get only text
    let selectedSectionContent = "";
    if(selectedSectionClass){
      const components = window.editor.DomComponents.getComponents();
      const selectedSection = components.find((component) =>
        component.getClasses().includes(selectedSectionClass)
      );
      if(selectedSection){
        selectedSectionContent = selectedSection?.getEl()?.innerText;
      }
    }

    const newMessage = {
      message,
      direction: "outgoing",
      sender: "user",
    };
    setColorOption(false);
    setColorButton(false);
    setBrandColor(false);
    setColorPickerVisible(false);
    setChatInputValue("");
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setIsTyping(true);
    setLoader(true);
    let promptMessage = [
      {
        role: "system",
        content: {
          selectedClass: selectedClass,
          selectedContentType: selectedContentType,
          selectedWordCount: selectedWordCount,
          selectedSectionClass: selectedSectionClass,
          textContent: textContent,
          selectedSectionContent: selectedSectionContent
        }
      },
      // {
      //   role: "user",
      //   content: `${message}\nNote: When rephrasing, make sure to include 'new_text' with the updated text, and provide the response in JSON format including section, action, text_count, and item.`
      // }
    ];
    
    try {
      await processMessageToChatGPT([...messages, newMessage], promptMessage);
    } catch (error) {
      console.error("Error processing message:", error);
    } finally {
      setIsTyping(false);
    }
  };

  const addNewButton = async (button_name, parentClass, element) => {
    let buttonName = button_name;
    if (button_name === "button") {
      buttonName = parentClass + "_button";
    }
    let buttonData;
    var clonePosition;
    var clonedChild;
    var filterClass;
    const addButtonData = addButton(buttonName);
    if (addButtonData.parentClassToFind !== parentClass) {
      buttonData = addButton(parentClass + "_button");
    } else {
      buttonData = addButtonData;
    }
    // Get all components
    const components = window.editor.DomComponents.getComponents();
    // Find the parent component by its class
    const parentComponent = components.find((component) =>
      component.getClasses().includes(parentClass)
    );

    if (parentComponent && buttonData) {
      // Find and clone the specified child component within the parent component
      clonedChild = findAndCloneChildComponent(
        parentComponent,
        buttonData.childClassToClone
      );

      clonePosition = findAndCloneChildComponent(
        parentComponent,
        buttonData.childClassWhereToClone
      );

      if (clonedChild.clonedComponent) {
        filterClass = buttonData.childClassWhereToClone;
        if (!clonePosition.childComponentData) {
          clonePosition = findAndCloneChildComponent(
            parentComponent,
            buttonData.flex_class
          );

          filterClass = buttonData.flex_class;
        }
        // Access the view object of the position component
        const clonePositionView = clonePosition.childComponentData.view;

        // Check if the $el property contains the desired div directly
        if (clonePositionView.$el.length) {
          // Get the specific div where you want to append the cloned component
          const specificDiv = clonePositionView.$el.filter(`.${filterClass}`);

          // Check if the specific div exists
          if (specificDiv.length) {
            // const childClass = clonedChild.clonedComponent.getClasses();

            // Get the element associated with the component
            const el = clonedChild.childComponentData.getEl();

            // Get the text content of the element
            const componentText = el.textContent.trim();
            const cssData = editor.getCss();
            if(cssData){
              const className = buttonData.new_class;
              dispatch(addButtonClass({cssData,variantId,className})).then(async (response) => {
                const { data } = response;
                if(data?.status === false && data?.css !== null){
                  setCssApplyStatus(true);
                  // Apply the CSS rules
                  editor.Css.addRules(data.css);
                }
              })
              .catch((error) => {
                console.log(error);
                // Handle error as needed
              });
            }
            // Create a new component with dynamic content
            // const newComponent = window.editor.DomComponents.addComponent({
            //   tagName: "a",
            //   attributes: {
            //     class: `${buttonData.new_class}`,
            //     id: `${buttonData.new_id}`,
            //     type: "button",
            //     href:"#",
            //     "data-gjs-type":"link"
            //   },
            //   content: componentText,
            // });

            const newComponent= `<a id=${buttonData.new_id}  href="#" type="button" class=${buttonData.new_class}>
            ${componentText}
        </a>`;

            editor.refresh();
            console.log("newComponent",newComponent);

            // Append the new component to the position component
            clonePosition.childComponentData.append(newComponent);
            if (clonePositionView && clonePositionView.$el) {
              // Update the class of the component
              clonePosition.childComponentData.set("classes", [
                buttonData.flex_class,
              ]);
              // Remove the class from the component
              clonePosition.childComponentData.removeClass(
                buttonData.childClassWhereToClone
              );

              // Trigger the update event to inform GrapeJS about the changes
              editor.trigger(
                "component:update",
                clonePosition.childComponentData
              );
              editor.refresh();
            } else {
              console.error("Unable to access the component view.");
            }
          } else {
            console.error(
              `No div with class found within the position component.`
            );
          }
        } else {
          console.error(`No div found within the cloned component.`);
        }
      } else {
        console.error(
          `No child component found with the class '${buttonData.childClassToClone}' within the parent component.`
        );
        if (!clonePosition.childComponentData) {
          clonePosition = findAndCloneChildComponent(
            parentComponent,
            buttonData.flex_class
          );
        }
        const newComponent = window.editor.DomComponents.addComponent({
          tagName: "div",
          attributes: {
            class: `${buttonData.childClassToClone}`,
            id: `${buttonData.childClassToClone}`,
          },
          content: "Contact Us",
        });

        // Access the view object of the position component
        const clonePositionView = clonePosition.childComponentData.view;
        // Append the new component to the position component
        clonePosition.childComponentData.append(newComponent);
        if (clonePositionView && clonePositionView.$el) {
          // Update the class of the component
          // clonePosition.childComponentData.set("classes", [
          //   childClassWhereToClone,
          // ]);
          // Remove the class from the component
          // clonePosition.childComponentData.removeClass(flex_class);

          // Trigger the update event to inform GrapeJS about the changes
          editor.trigger("component:update", clonePosition.childComponentData);
          editor.refresh();
        }
      }
    } else {
      console.error(
        `No parent component found with the class '${parentClass}'.`
      );
    }
  };

  async function processMessageToChatGPT(chatMessages, prompt) {
    chatMessages = chatMessages.filter(
      (message) => message.sender !== "ChatGPT"
    );
    const apiMessages = chatMessages.map((messageObject) => {
      const role = messageObject.sender === "ChatGPT" ? "assistant" : "user";
      return { role, content: messageObject.message };
    });
    const api_messages = [[...prompt, ...apiMessages], templatedId];
    dispatch(chatBoxOpenAI(api_messages))
      .then(async (response) => {
        setButtonDisabled(false);
        const content = response;
        let jsonNewData =  content;
        // If `content` is a string, parse it, otherwise keep it as is
          if (typeof content === 'string') {
            jsonNewData = JSON.parse(content);
          }
        let  staticContent;
        if(jsonNewData?.action === "retry"){
          setErrorMsg(true);
           staticContent =  "I apologize, but I'm having trouble understanding your request. Could you please rephrase it in a different way?";
        }else{
           staticContent =  "Attempted";
        }
       
        
        setButton(false);
        setLoader(false);
        if (jsonNewData.type === "remove_item") {
          if (getSelected) {
            getSelected.remove();
          }
        }

        if (jsonNewData.action ==="remove_section") {
          const topLevelAncestor = await findTopLevelAncestor(getSelected);
          const selectedParentId = topLevelAncestor.getId();
          const components = window.editor.DomComponents.getComponents();
          let found = false;

          components.each(function (component) {
            if (!found && selectedParentId === component.getId()) {
              component.remove();
              found = true; // Set the flag to true to indicate the component is found
            }
          });
          if (!found) {
            console.error(`Component with ID ${selectedParentId} not found.`);
          }
          editor.refresh();
        }

        if (jsonNewData.action === "add") {
          const topLevelAncestor = findTopLevelAncestor(getSelected);
          const parentClasses = topLevelAncestor.getClasses(); //parent class
          const parentClassToFind = parentClasses[0]; //parent class
          // const childClassToClone = "howItWork"; // Replace 'your-class-name' with Class to determine button placement
          // const childClassWhereToClone = "slides";
          if (
            jsonNewData.item === "header_button" ||
            jsonNewData.item === "benefits_button" ||
            jsonNewData.item === "service_button" ||
            jsonNewData.item === "review_button" ||
            jsonNewData.item === "about_us_button" ||
            jsonNewData.item === "button" ||
            jsonNewData.type === "button"
          ) {
            await addNewButton(
              jsonNewData.item === "about_us_button" ? "about_button" : jsonNewData.item,
              parentClassToFind,
              jsonNewData.type 
            );
            editor.refresh();
          
          } else {
            for (const key in addData) {
              if (key === parentClassToFind) {
                // Get all components
                const components = window.editor.DomComponents.getComponents();

                // Find the parent component by its class
                const parentComponent = components.find((component) =>
                  component.getClasses().includes(parentClassToFind)
                );

                if (parentComponent) {
                  var clonedChild;
                  var designData;
                  var clonePosition;
                  // var clonedChildClass;
                  var clonedPositionClass;
                  if (key === "benefits") {

                    for (const config of addData[key]) {
                      if(config.childClassToClone === "designType"){
                        designData = findComponentType(
                          parentComponent,
                          config.childClassToClone
                        );
                          // Find and clone the specified child component within the parent component
                          clonedChild = findCloneComponentByType(
                            parentComponent,
                            designData
                          );
                      }else{
                        clonedChild = findAndCloneChildComponent(
                          parentComponent,
                          config.childClassToClone
                        );
                      }
                      clonePosition = findAndCloneChildComponent(
                        parentComponent,
                        config.childClassWhereToClone
                      );
                      clonedPositionClass = config.childClassWhereToClone;
                      if (clonedChild.componetHtml && clonePosition.componetHtml) break;
                  }
                  
                    
                  } else {
                    
                    // Iterate over each configuration in the array for 'review'
                    for (const config of addData[key]) {

                        // Find and clone the specified child component within the parent component
                        clonedChild = findAndCloneChildComponent(
                            parentComponent,
                            config.childClassToClone
                        );

                        // clonedChildClass = config.childClassToClone;

                        // Find the position component within the parent component
                        clonePosition = findAndCloneChildComponent(
                            parentComponent,
                            config.childClassWhereToClone
                        );

                        clonedPositionClass = config.childClassWhereToClone;

                        // If both the cloned child and position are found, break the loop
                        if (clonedChild.componetHtml && clonePosition.componetHtml) break;
                      }
                  }

                  if (clonedChild && clonePosition && clonedChild.clonedComponent) {
                    var indexCount;
                    if (key === "benefits") {
                      if(designData){
                        indexCount =
                        designData.leftCount + designData.rightCount + 1;
                      }else{
                        indexCount = clonedChild.childIndex + 2;
                      }
                     
                    } else {
                      indexCount = clonedChild.childIndex + 2;
                    }

                    // Access the view object of the position component
                    const clonePositionView =
                      clonePosition.childComponentData.view;
                    // Check if the $el property contains the desired div directly
                    if (clonePositionView.$el.length) {
                      // Get the specific div where you want to append the cloned component
                      const specificDiv = clonePositionView.$el.filter(
                        `.${clonedPositionClass}`
                      );
                      // Check if the specific div exists
                      if (specificDiv.length) {

                        // Manipulate the cloned component's HTML string to update ID and class
                        const idMatches =
                          clonedChild.componetHtml.match(/id="([^"]*)"/g);
                        if (idMatches) {
                          const ids = idMatches.map(
                            (match) => match.match(/id="([^"]*)"/)[1]
                          );
                          // Remove the last two characters and add dynamic values
                          const modifiedIds = ids.map((id) => {
                            // Remove the last two characters using slice()
                            const withoutLastTwo = id.slice(0, -2);

                            // Add dynamic value here (for example, "_new")
                            return withoutLastTwo + `_${indexCount}`;
                          });
                          let updatedHtml = clonedChild.componetHtml;
                          let step = 0;
                          // Loop through the IDs array and replace each occurrence of the original ID with the modified ID
                          for (let i = 0; i < ids.length; i++) {
                            const regex = new RegExp(`id="${ids[i]}"`, "g");

                            updatedHtml = updatedHtml.replace(
                              regex,
                              `id="${modifiedIds[i]}"`
                            );

                            const regex1 = new RegExp(`for="${ids[i]}"`, "g");

                            updatedHtml = updatedHtml.replace(
                              regex1,
                              `for="${modifiedIds[i]}"`
                            );
                            step = i;
                          }
                          const func = functionMap[key]; // Get the function based on the key

                          // Check if the function exists before calling it
                          if (typeof func === "function") {
                            updatedHtml = func(updatedHtml, step, getSelected.getClasses(),jsonNewData.type );
                          } else {
                            console.error(
                              `Function ${key}Data does not exist.`
                            );
                          }

                          if(updatedHtml){
                          console.log("updatedHtml",updatedHtml);
                            clonePosition.childComponentData.append(updatedHtml);
                            // Trigger the update event to inform GrapeJS about the changes
                            editor.trigger(
                              "component:update",
                              clonePosition.childComponentData
                            );
                           
                          }
                          editor.refresh();
                        } else {
                          console.log("No IDs found.");
                        }
                      } else {
                        console.error(
                          `No div with class 'slides' found within the position component.`
                        );
                      }
                    } else {
                      console.error(
                        `No div found within the cloned component.`
                      );
                    }
                  } else {
                    console.error(
                      `NNo child component found with the class within the parent component.`
                    );
                  }
                } else {
                  console.error(
                    `No parent component found with the class '${parentClassToFind}'.`
                  );
                }
              }
            }
          }
        }

        // if (jsonNewData.action === "remove") {
        //   if (getSelected.get("tagName") === "li" && jsonNewData.liCount <= 1) {
        //     getSelected.remove();
        //   } else if (jsonNewData.type === "button") {
        //     getSelected.remove();
        //   } else {
        //     const topLevelAncestor = findTopLevelAncestor(getSelected);
        //     const selectedParentId = topLevelAncestor.getId();
        //     let liCount = jsonNewData?.liCount ? jsonNewData.liCount : 1;
        //     const parentClasses = topLevelAncestor.getClasses();
        //     let found = false;
        //     let Elements = [];
        //     let topAncestor = "";
        //     if (getSelected.get("tagName") === "li") {
        //       getSelected.remove();
        //       liCount--;
        //     }

        //     // Define a recursive function to traverse through all children and descendants
        //     const gatherElements = (component) => {
        //       const id = getSelected.getId();
        //       if (component.get("tagName") === "li") {
        //         Elements.push(component);
        //       } else if (parentClasses[0] === "benefits") {
        //         if (id === component.getId()) {
        //           topAncestor = findAncestor(component, "designtype");
        //         }
        //         if (!topAncestor) {
        //           const attributes = component.getAttributes();
        //           // Check design type for benefits section only
        //           if (attributes && attributes.designtype) {
        //             for (const key in removeData) {
        //               if (parentClasses[0] === key) {
        //                 Elements.push(component);
        //               }
        //             }
        //           }
        //         }
        //       } else {
        //         for (const key in removeData) {
        //           if (parentClasses[0] === key) {
        //             if (id === component.getId()) {
        //               topAncestor = findAncestor(
        //                 component,
        //                 removeData[key].class
        //               );
        //             }
        //             if (!topAncestor) {
        //               if (
        //                 parentClasses[0] === key &&
        //                 component.getClasses().includes(removeData[key].class)
        //               ) {
        //                 Elements.push(component);
        //               }
        //             }
        //           }
        //         }
        //       }
        //       if (topAncestor) {
        //         return topAncestor;
        //       }
        //       // Recursively traverse through all children and descendants
        //       component.components().each(gatherElements);
        //     };
        //     const components = window.editor.DomComponents.getComponents();

        //     components.each(function (component) {
        //       if (!found && component.getClasses().includes(parentClasses[0])) {
        //         component.components().each(gatherElements);
        //         found = true; // Set the flag to true to indicate the component is found
        //       }
        //     });
        //     if (topAncestor) {
        //       topAncestor.remove();
        //     }

        //     // Remove the last n <li> elements if there are enough <li> elements
        //     if (Elements.length > liCount) {
        //       // Remove the last n  elements
        //       const lastIndex = Elements.length - 1;
        //       for (let i = lastIndex; i > lastIndex - liCount; i--) {
        //         Elements[i].remove();
        //       }
        //     } else {
        //       console.error("There are not enough elements to remove.");
        //     }
        //     if (!found) {
        //       console.error(`Component with ID ${selectedParentId} not found.`);
        //     }
        //   }
        // }        

        if (jsonNewData.action === "remove") {
          if ((getSelected.get("tagName") === "li"|| (getSelected.parent()).get("tagName") === "li") && jsonNewData.liCount <= 1) {

            (getSelected.parent()).get("tagName") === "li" ? (getSelected.parent()).remove() : getSelected.remove();

          } else if (jsonNewData.type === "button") {
            getSelected.remove();
          } else {
            const topLevelAncestor = findTopLevelAncestor(getSelected);
            // console.log("topLevelAncestortopLevelAncestortopLevelAncestortopLevelAncestortopLevelAncestortopLevelAncestortopLevelAncestortopLevelAncestortopLevelAncestortopLevelAncestor", topLevelAncestor, {jsonNewData});
            const selectedParentId = topLevelAncestor.getId();
            let liCount = jsonNewData?.liCount ? jsonNewData.liCount : 1;
            const parentClasses = topLevelAncestor.getClasses();
            let found = false;
            let Elements = [];
            let topAncestor = "";
        
            // if (getSelected.get("tagName") === "li"|| (getSelected.parent()).get("tagName") === "li") {
            //   (getSelected.parent()).get("tagName") === "li" ? (getSelected.parent()).remove() : getSelected.remove();
            //   liCount--;
            // }
        
            // Function to gather elements for removal
            const gatherElements = (component) => {
              const id = getSelected.getId();
              // if (component.get("tagName") === "li") {
              //   Elements.push(component);
              // } else {
                 // Skip adding li elements to Elements array
                if (component.get("tagName") === "li") {
                  return;
                }
                const matchingKey = parentClasses[0];
                // Check for the existence of the matching section in removeData
                if (removeData[matchingKey]) {
                  const sectionItems = removeData[matchingKey];
                  if (Array.isArray(sectionItems)) {
                    sectionItems.forEach(item => {
                      if (component.getClasses().includes(item.class)) {
                        Elements.push(component);
                      }
                    });
                  } else if (component.getClasses().includes(sectionItems.class)) {
                    Elements.push(component);
                  }
        
                  if (matchingKey === "benefits" && !topAncestor) {
                    console.log("benefits",component);
                    const attributes = component.getAttributes();
                    if (attributes && attributes.designtype) {
                      topAncestor = findAncestor(component, "designtype");
                      Elements.push(component);
                    }
                  }
                }
              // }
        
              if (topAncestor) {
                return topAncestor;
              }
        
              // Recursively check child components
              component.components().each(gatherElements);
            };
        
            const components = window.editor.DomComponents.getComponents();
        
            components.each(function (component) {
              if (!found && component.getClasses().includes(parentClasses[0])) {
                component.components().each(gatherElements);
                found = true; // Component found
              }
            });
        
            if (topAncestor) {
              topAncestor.remove();
            }

                      
        
            // Remove the last n <li> elements if there are enough
            if (Elements.length > liCount) {
              const selectedIndex = Elements.findIndex((elem) => {
                return elem === getSelected || elem === getSelected.parent() || elem === getSelected.parent()?.parent()|| elem === getSelected.parent()?.parent()?.parent();
              });
              
              if (selectedIndex !== -1) {
                // Remove the selected component from the Elements array
                Elements[selectedIndex].remove();
                liCount--; // Decrement the liCount by 1 after removing the component
              }  
              const lastIndex = Elements.length - 1;
              for (let i = lastIndex; i > lastIndex - liCount; i--) {
                Elements[i].remove();
                
              }
            } else {
              console.error("There are not enough elements to remove.");
            }
        
            if (!found) {
              console.error(`Component with ID ${selectedParentId} not found.`);
            }
          }
          
        }


        
        
        
        if (
          jsonNewData.new_text &&
          jsonNewData.new_text !== "null" &&
          jsonNewData.action === "rephrase"
        ) {
          const selectedComponent = editor.getSelected();
          if (selectedComponent) {
            const components = selectedComponent.components();
            const numComponents = components.length;
            const newText = jsonNewData.new_text;

            if (numComponents > 1) {
              const words = newText.split(' ');
              const wordCount = words.length;
              // Calculate the number of words per part
              const partLength = Math.ceil(wordCount / numComponents);

               // Prepare the components to hold the parts of the text
              const textParts = [];

              for (let i = 0; i < numComponents; i++) {
                const start = i * partLength;
                const end = start + partLength;
                const partWords = words.slice(start, end); // Get the words for the current part
                textParts.push(partWords.join(' ')); // Join the words back into a string
            }
        
            // Update the components with the split text
            components.forEach((component, index) => {
                if (index < numComponents) {
                    const partText = textParts[index]+ ' ';
                    // Ensure the component is of the right type
                    // if (component.get('type') === 'textnode') {
                        // Clear existing components and set new content
                        // component.components().reset(); // Remove existing inner components if any
                        // component.append(partText); // Append the new text content
                        // component.view.render(); // Re-render to apply the change
                        // component.replaceWith(partText);
                        component.replaceWith(partText);
                    // }
                }
            });
            } else if (numComponents === 1) {
              components.forEach((component) => {
                component.replaceWith(jsonNewData.new_text);
            });
            }
            editor.refresh();
          }
        }

        if (jsonNewData.style && jsonNewData.style != null) {
          // Parse the JSON data to a JavaScript object
          const styleProperties = getStyleProperties(jsonNewData.style);

          if(styleProperties[0]?.value === "" || styleProperties[0]?.value === null || styleProperties[0]?.value === undefined){
            // setColorButton(true);  
          }
          const hasColor = jsonNewData.style.match(/(?<!background-)color:\s*([^;]+);/) !== null;
          // const checkValue = jsonNewData.style.match(/linear-gradient:\s*([^;]+);/) != null;
          const hasBackgroundColor = jsonNewData.style.match(/background-color:\s*([^;]+);/) !== null;
          if(hasBackgroundColor || hasColor){
            setStyleData(jsonNewData.style);
            // setColorButton(true);
          }
            // if styleContent.class is present in sting jsonNewData.style
            else if (jsonNewData.style.includes(styleContent.class)) {
             
              const css = editor.Css;
              if (css && (!hasBackgroundColor || !hasColor)) {
                const rule = css.getRule(`.${styleContent.class}`);
                if (rule) {
                  if (styleProperties[0].property in rule.config.style) {
                    // If exists, update its value
                    rule.set('style', {
                      ...rule.get('style'),
                      [styleProperties[0].property]: styleProperties[0].value
                    });
                  }
                } else {
                  // If class not exists, add css
                  editor.addStyle(jsonNewData.style);
                }
              }
              // editor.refresh();
              // editor.addStyle(jsonNewData.style);
            } else {
              editor.addStyle(`.${styleContent.class}${jsonNewData.style}`);
            }
          
          // editor.refresh();
        }

        if (content) {
          dispatch({ type: "EDITOR_CHANGE_STATUS", payload: true });
          faqChecked(window.editor);
          const chatGPTResponse = {
            message: staticContent,
            sender: "ChatGPT",
          };
          if(jsonNewData?.action === "style"){
            const hasColor = jsonNewData.style.match(/(?<!background-)color:\s*([^;]+);/) !== null;
            const hasBackgroundColor = jsonNewData.style.match(/background-color:\s*([^;]+);/) !== null;
            const styleContainsButton = /\.([a-zA-Z0-9_]*button[a-zA-Z0-9_]*)\s*\{/g.test(jsonNewData.style);
            if(styleContainsButton){
              setElementType("button"); 
            }
            if(hasColor || hasBackgroundColor){
              if(!styleContainsButton){
                setElementType("text"); 
              }
            setColorOption(true);
            fetchDefaultColor(jsonNewData.style);
            }else{
              setMessages((apiMessages) => [...apiMessages, chatGPTResponse]);
            }
           
          }else{
            setColorButton(false);
            setBrandColor(false);
            setColorPickerVisible(false);
            setColorOption(false);
            setMessages((apiMessages) => [...apiMessages, chatGPTResponse]);
          }
          
        }
        setEditorSettings();
      })
      .catch((error) => {
        console.log(error, "error");
        // Handle error as needed
      });
  }

  const setEditorSettings = () =>{
    const targetClasses = ["about_us","benefits", "work","review","service","faq","footer","gallery_1","gallery_2","gallery_3","gallery_4","gallery_5","pricing_1","pricing_2","sponsor-3","sponsor-2","sponsor-1"];

    const selectedHoverClasses = ["header_second_inner","testimonial_outer","header_img_bg","about-image-bg","star_icon","benefits-image-bg","benefits-image-reverse-bg","testimonial_gradient","header_second_inner_bg_gradiant","header_circle_outer_layer","gradiant-square-top","gradiant-square-bottom", "header_circle_outer_layer_rev"];
    window.editor.getComponents().forEach((component) => {
      component.onAll((component) => {
          component.set("draggable", false);
          component.set("hoverable", false);
          component.set("badgable", false);
          component.set("selectable", false);
        if(component.get("tagName") === "span"){
          component.set("selectable", true);
          component.set("hoverable", true); 
        }
          
          if(component.get("tagName") === "section"|| component.get("type") === "link" ){
            component.set("selectable", true);
            component.set("hoverable", true);
          }


        if (component.get("tagName") === "img" || component.get("tagName") === "iframe" || component.get("type") === "iframe") {
          component.set("selectable", true);
          component.set("hoverable", true);
          component.set("resizable", false);
        }

        if (component.get("tagName") === "div") {
          if (component.getClasses().includes("facebook-icon")){
            component.set("selectable", false);
            component.set("hoverable", false);
          }

          
          const classes = component.getClasses(); // Get the array of classes
          const hasClass = selectedHoverClasses.some(cls => classes.includes(cls));
      
          // Check if the component includes any of the target classes
          const hasTargetClass = targetClasses.some(cls => classes.includes(cls));
      
          if (hasTargetClass || hasClass) {
              component.set("selectable", true);
              component.set("hoverable", true);
          }
      }

        if (
          component.getClasses().includes("header_img_circle") ||
          component.getClasses().includes("testimonial-quotes-img") ||
          component.getClasses().includes("benefits-wave-img") ||
          component.getClasses().includes("empty-reactangle-blue") ||
          component.getClasses().includes("reactangle-blue") ||
          component.getClasses().includes("reactangle-pink") ||
          component.getClasses().includes("empty-reactangle-pink")||
          component.getClasses().includes("header_circle_outer_layer_img")
        ) {
          component.set("draggable", false);
          component.set("hoverable", false);
          component.set("badgable", false);
          // component.set("selectable", false);
        }
        if (component.get("type") === "text") {
          component.set("selectable", true);
          component.set("hoverable", true);
        }
        const attributeData = component.getClasses();
        const classArray = selectedClasses;

        for (let i = 0; i < attributeData.length; i++) {
          if (classArray.includes(attributeData[i])) {
            component.set("selectable", true);
            component.set("hoverable", true);
          }
        }
      });
    });
  }
  // const handleButtonClickText = (e) => {
  //   handleSendRequest("repharse the text");

  //   // Handle button click logic here
  // };

  const handleButtonClick = async (colour) => {
    setActionType({ type: "websiteColor", value: colour });

    const colorData =  getStyleProperties(styleData);
    if(colorData){
     setColorCss(colorData[0], colour);
    }
  };

  const handleAllColor = async () => {
    setColorApplyType("all");
    setButtonModal(false);
    setActionType("");
    setColorButton(false);
    setColorPickerVisible(false);
    setButton(false);
    var color;
    var styleProperties;

    // if(elementType === "text"){
    //   if(actionType.type === "websiteColor"){
    //     console.log("websiteColor",actionType.value);
    //     const propertyData = getStyleProperties(styleData);
    //     styleProperties =  propertyData[0];
    //     color = actionType.value;
    //   }else{
    //     color = pickerColor; 
    //     const stylePropertiesData = getStyleProperties(styleData);
    //     if(stylePropertiesData[0].className){
    //       styleProperties = stylePropertiesData[0];
    //      }
    //   }
    // }
    if(actionType.type === "websiteColor"){
      const propertyData = getStyleProperties(styleData);
      styleProperties =  propertyData[0];
      color = actionType.value;
    }else{
      color = pickerColor; 
      const stylePropertiesData = getStyleProperties(styleData);
      if(stylePropertiesData[0].className){
        styleProperties = stylePropertiesData[0];
       }
    }
    if(styleProperties){
      const css = editor.Css;
      const hasColor = styleProperties.property == "color";
      const hasBackgroundColor = styleProperties.property == "background-color";
      var classesData;
      var checkButtonElement = false;
      if(elementType === "text"){
        if (headerClasses.includes(styleProperties.className)) {
          classesData = headerClasses;
        }else if (textClasses.includes(styleProperties.className)) {
          classesData = textClasses;
        }
      }else{
        if (/.*_button_two$/.test(styleProperties.className)) {
          classesData = buttonClassesTwo;
          checkButtonElement = true;
        }else{
          classesData = buttonClasses;
          checkButtonElement = true;
        }
      }

      const selectedrule = css.getRule(`.${styleProperties.className}`);
      if(selectedrule){
        // please apply here loop to getRule according to class
        classesData.forEach((buttonClass) => {
          const rule = css.getRule(`.${buttonClass}`);
          if(rule){
            if(hasBackgroundColor){
              // change gradiant background color
              const backgroundImage = rule?.attributes?.style['background-image'];
              if(backgroundImage){
                rule.set('style', {
                  ...rule.get('style'),
                  'background-image': `linear-gradient(83.96deg, ${color}, ${color})`
                });
              }else{
                rule.set('style', {
                  ...rule.get('style'),
                  [styleProperties.property]: color
                });
              }
            }
            if(hasColor){
              if(backgroundClasses.includes(buttonClass) && !checkButtonElement){
                
                rule.set('style', {
                  ...rule.get('style'),
                  'background-color': color
                });
              }else  if(borderClasses.includes(buttonClass)  && !checkButtonElement){
                rule.set('style', {
                  ...rule.get('style'),
                  'border-bottom-color': color
                });
              }
              else{
                // change gradiant text color
                const backgroundImage = rule?.attributes?.style['background-image'];
                const backgroundClip = rule?.attributes?.style['background-clip'];
                if(backgroundImage && backgroundClip){
                  rule.set('style', {
                    ...rule.get('style'),
                    'background-image': `linear-gradient(83.96deg, ${color}, ${color})`
                  });
                }else{
                  rule.set('style', {
                    ...rule.get('style'),
                    [styleProperties.property]: color
                  });
                }
              }
            }
          
          }
        });
      }else{
        console.log("No rule found");
        editor.addStyle(`.${styleProperties.className}{${styleProperties.property}: ${color}}`);
      } 
    } 
  }

  const handleSectionClick = (type) => {
    if (type === "pricing") {
      setSectionType("pricing");
      setSelectSection(true);
    }
    if (type === "gallery") {
      setSectionType("gallery");
      setSelectSection(true);
    }
    if (type === "partners") {
      setSectionType("partners");
      setSelectSection(true);
    }
  };


  const handleAddSection = (name) => {
    setIsLoading(true);
    dispatch(addNewSection({ name}))
      .then((response) => {
       const classData = getSelected.getClasses();
        // Find all components with the class 'my-class'
        const componentsWithClass = window.editor.getWrapper().find(`.${classData[0]}`);

        // Filter to get only 'div' elements
        const divComponents = componentsWithClass.filter(comp => comp.get('tagName') === 'div');

        if (divComponents.length) {
          const targetDiv = divComponents[0]; // Assuming you want the first div with the class

           // Create a new component to hold the HTML content
            // const newComponent = window.editor.DomComponents.addComponent({
            //   tagName: 'div', // or whatever tag you want to use
            //   content: response.data.html,
            // });
          
             const parent = targetDiv.parent();
              parent.append(response.data.html, {
                at: targetDiv.index(), // Move the newComponent before targetDiv
              });
              // Apply the CSS rules
            editor.Css.addRules(response.data.css); 
          setTimeout(() => {
            dispatch({ type: "SECTION_ADD_STATUS", payload: false });
            dispatch({ type: "EDITOR_CHANGE_STATUS", payload: true });
            setVisibility(false);
            setSection(false);
            setSelectSection(false);
            setIsLoading(false);
            setSectionType("");
          }, 3000);
        } else {
          console.log('No div with class "my-class" found.');
        }

      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleSelectChange = async (event) => {
    setSelectedOption("event.target.value");
    setVisible((prevExpanded) => !prevExpanded);
    setExpanded((prevExpanded) => !prevExpanded);

    dispatch(templateSection({ event, templatedId }))
      .then((response) => {
        if (response.status === 200) {
          setImages(response.data.data);
        } else {
          console.error("Error fetching website Data", response.error);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    if(getSelected){
      setEditorSettings();
    }
    if (selectedOption) {
      // fetchData();  
    }
  }, [selectedOption, getSelected]);

  const handleImageDoubleClick = () => {
    setSelectedImageObj(images);
    setModalOpened(true);
  };

  const handleImageClick = (index) => {
    setSelectedImageObj(images[index]);
    // Access the name and URL from the selected image object

    setSelectedImage(index);
  };
  const handelRequest = async () => {
    try {
      // const changed = await authApi.post(API_URL + "template/entry", {
      //   name: images.name,
      //   type: images.type,
      //   userId: userId,
      //   websiteId: id,
      // });
      setModalOpen(false);
      window.location.reload();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const toggleButton1 = () => {
  //   setButton(!button);
  //   setStyle(false); // Close button 2 when opening button 1
  //   setShowListOnHover(false);
  // };

  // const toggleButton2 = () => {
  //   setStyle(!style);
  //   setButton(false); // Close button 1 when opening button 2
  //   setShowListOnHover(false);
  // };

  return (
    <>
      {selectSection && (
        <div className=" edit-moadal absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white px-7 py-10 rounded relative w-[1200px]">
            <div className="flex justify-between">
              <h2 className="text-[20px] text-black">Choose a Section</h2>
              <button
                type="button"
                onClick={() => {
                  closeSectionModal();
                }}
                className="text-[20px] text-black"
              >
                <Icon size={1} path={mdiClose} />
              </button>
            </div>
            <div className="flex mt-10 w-[1140px] h-[446px]">
              <div className="outline outline-1 outline-[#959595] rounded-xl overflow-y-auto w-[100%] px-10 pt-8">
                <div className="flex gap-x-5 h-[345px]">
                  {sectionType === "pricing" &&  TableImages.slice(0, 2).map((src, index) => ( 
                    <>
                      <img
                        key={index}
                        onClick={() => handleSelectedSection(index,sectionType)}
                        className={`w-[296px] h-[356px] transform transition-transform duration-300 ${selectedTableSection === index ? "scale-110" : "scale-100"}`}
                        src={src}
                        alt={`Table Image ${index + 1}`}
                      />
                    </>
                  ))}

                  {sectionType === "gallery" && GalleryImages.slice(0, 4).map((src, index) => ( 
                    <>
                      <img
                        key={index}
                        onClick={() => handleSelectedSection(index,sectionType)}
                        className={`w-[269px] h-[269px] transform transition-transform duration-300 ${selectedGallerySection === index ? "scale-110" : "scale-100"}`}
                        src={src}
                        alt={`Gallery Image ${index + 1}`}
                      />
                    </>
                  ))}

                  {sectionType === "partners" && PatnersImages.slice(0, 3).map((src, index) => ( 
                    <>
                      <img
                        key={index}
                        onClick={() => handleSelectedSection(index,sectionType)}
                        className={`w-[338px] h-[55px] transform transition-transform duration-300 ${selectedPatnersSection === index ? "scale-110" : "scale-100"}`}
                        src={src}
                        alt={`Logo Image ${index + 1}`}
                      />
                    </>
                   ))}
                </div>
                <div className="text-end mt-4">
                <button
                  onClick={() => {
                    closeSectionModal();
                  }}
                  type="button"
                  className="rounded-lg text-[12px] outline outline-1 outline-[#1A1A1A2E] text-center text-black font-medium w-[70px] h-[32px]"
                >
                  Cancel
                </button>
                <button
                onClick={() => {handleAddSection(sectionType +`_`+ (sectionIndex+1))}}
                  type="button"
                  className="ms-4 rounded-lg text-[12px] bg-[#2688D1] text-center font-medium text-white w-[109px] h-[32px]"
                >
                 {isLoading ?   <ButtonLoaderJoxy /> : "Add Section"} 
                </button>
              </div>
              </div>
             
            </div>
          </div>
        </div>
      )}
      {(visibility) && (
        <div className={`chatbox  `}>
          {/* {!minimized && ( */}
          <>
            <div className="chatboxSection">
              {showChatBox && (
                <div
                  style={{
                    boxShadow: "-4px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                  }}
                  className={`fixed bottom-[calc(0rem+0.5rem)] right-0 mr-4 bg-white px-4 py-6 rounded-2xl w-[328px] md:w-[469px]  ${
                    minimized ? "h-[70px]" : "h-screen max-h-[574px]"
                  }`}
                >
                  <div
                    className={`flex flex-col space-y-1.5  ${
                      minimized ? "pb-0" : "pb-6"
                    }`}
                  >
                    <div
                      className={`chat-header ${
                        minimized ? "" : "pb-4 border-b border-[#CECECE]"
                      }`}
                    >
                      {" "}
                      <div className="flex">
                        {" "}
                        <img
                          src="/images/new_theme/Joxy_favicon.svg"
                          className=""
                          alt=""
                        />
                        <div className="chat-header-text">Copilot</div>
                      </div>
                      <div className="sideIcons">
                        {!minimized && (
                          <>
                            {/* <button className="minimize-button">
                              <Icon
                                onClick={() => {
                                  setShowListOnHover(!showListOnHover);
                                  if (style) {
                                    setStyle(false);
                                  }
                                  if (button) {
                                    setButton(false);
                                  }
                                }}
                                size={1.0}
                                path={mdiHelpCircle}
                                color="#0767FD"
                              />
                            </button> */}
                            <button className="minimize-button">
                              <Icon
                                onClick={toggleMinimized}
                                size={1.0}
                                path={mdiMinus}
                                color="black"
                              />
                            </button>
                          </>
                        )}
                        {minimized && (
                          <button className="minimize-button">
                            <Icon
                              onClick={toggleMinimized}
                              size={1.0}
                              path={mdiWindowMaximize}
                              color="black"
                            />
                          </button>
                        )}

                        <Icon
                          onClick={toggleChatbox}
                          size={1.0}
                          path={mdiClose}
                          color="black"
                        />
                      </div>
                    </div>
                  </div>
                  
                  {!minimized && (
                    <div className="max-h-[465px] h-screen min-w-full flex flex-col justify-between">
                      <div className="min-w-full overflow-x-auto">
                         {/* {((!showListOnHover && !getSelected) ||
                          getSelected) && ( */}
                            {(!getSelected ||  getSelected) &&  (
                              <>
                                <div className="flex gap-3 mb-4 justify-center flex-1 clear-both">
                                  <button
                                    className="border text-[10px] rounded px-4 py-2 text-nowrap w-full border-black text-black hover:text-white hover:border-white hover:bg-[linear-gradient(135deg,_#02BFD5_0%,_#2688D1_29.07%,_#F92672_100%)] transition-all duration-300"
                                    onClick={()=>{setIsModalOpen(true)}}
                                  >
                                    Add DNS
                                  </button>
                                  <button
                                    className="border text-[10px] rounded px-4 py-2 text-nowrap w-full border-black text-black hover:text-white  hover:border-white hover:bg-[linear-gradient(135deg,_#02BFD5_0%,_#2688D1_29.07%,_#F92672_100%)] transition-all duration-300"
                                    onClick={()=>{setOpenAddTagModal(true)}}
                                  >
                                    Add Scripts
                                  </button>
                                  <button
                                    className="border text-[10px] rounded px-4 py-2 text-nowrap w-full border-black text-black hover:text-white  hover:border-white hover:bg-[linear-gradient(135deg,_#02BFD5_0%,_#2688D1_29.07%,_#F92672_100%)] transition-all duration-300"
                                    onClick={()=>{setOpenEditPagePropModal(true)}}
                                  >
                                    Edit Page Properties
                                  </button>
                                </div>
                              </>
                            )}
                       
                        {messages.map((message, i) => {
                          const isLastMessage = i !== 0 && i === messages.length - 1;
                          const isChatGPT = message.sender === "ChatGPT";
                          const showAfterChatGPT = (isLastMessage) && colorButton && websiteColor;
                          // Check if the current message is the last non-ChatGPT message
                          const isLastNonChatGPT = isLastMessage && !isChatGPT;
                          return (
                            <div key={i}>
                              {isChatGPT ? (
                                <React.Fragment>
                                  <div className="flex gap-3 mb-4 text-black text-sm flex-1 float-left clear-both w-[70%]">
                                    <span className="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
                                      <div className="rounded-full bg-gray-100 border p-1">
                                        <img src="/favicon/favicon.ico" alt="" />
                                      </div>
                                    </span>
                                    <p className="leading-relaxed bg-[#E3E3E3] rounded-lg py-4 px-3">
                                      {message.message}
                                    </p>
                                    {!errorMsg && isLastMessage && isChatGPT && (
                                      <button
                                        className="font-semibold text-sm p-1 rounded inline-flex items-center h-5 self-end text-white"
                                        style={{
                                          backgroundColor: buttonDisabled ? "#e5e7eb" : "#3b97e3",
                                          fontSize: "12px",
                                        }}
                                        data-twe-toggle="tooltip"
                                        data-twe-placement="right"
                                        data-twe-ripple-init
                                        data-twe-ripple-color="light"
                                        title="undo changes"
                                        onClick={handleUndoButtonClick}
                                        disabled={buttonDisabled}
                                      >
                                        <Icon path={mdiArrowULeftTop} size="15px" color="#fff" />
                                      </button>
                                    )}
                                  </div>
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <div className="flex gap-3 my-4 text-sm flex-1 float-right clear-both">
                                    <p className="leading-relaxed text-white bg-[#0767FD] rounded-lg py-4 px-3">
                                      {message.message}
                                    </p>
                                  </div>
                                  {(isLastNonChatGPT && colorOption) &&  (
                                    <div>
                                      <div className="flex gap-3 mb-4 text-black text-sm flex-1 float-left clear-both w-[70%]">
                                    <span className="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
                                      <div className="rounded-full bg-gray-100 border p-1">
                                        <img src="/favicon/favicon.ico" alt="" />
                                      </div>
                                    </span>
                                    <p className="leading-relaxed bg-[#E3E3E3] rounded-lg py-4 px-3">
                                    Select how you would like to update the color:
                                    </p>
                                    </div>
                                    <div className="flex gap-3 ml-11 mb-4 text-black text-sm flex-1 float-left clear-both w-[80%]">
                                      <button
                                     className= {cx(
                                        "border text-[10px] rounded px-4 py-2 text-nowrap w-full   hover:text-white hover:border-white hover:bg-[linear-gradient(135deg,_#02BFD5_0%,_#2688D1_29.07%,_#F92672_100%)] transition-all duration-300",
                                        colorPickerVisible
                                          ? "bg-[linear-gradient(135deg,_#02BFD5_0%,_#2688D1_29.07%,_#F92672_100%)] border-white text-white"
                                          : `border-black text-black`,
                                      )}
                                        onClick={(e) => {setColorPickerVisible(true); setColorButton(false); setBrandColor(false);}}
                                      >
                                        Use color picker
                                        
                                      </button>
                                      <button
                                        className= {cx(
                                          "border text-[10px] rounded px-4 py-2 text-nowrap w-full   hover:text-white hover:border-white hover:bg-[linear-gradient(135deg,_#02BFD5_0%,_#2688D1_29.07%,_#F92672_100%)] transition-all duration-300",
                                          colorButton
                                            ? "bg-[linear-gradient(135deg,_#02BFD5_0%,_#2688D1_29.07%,_#F92672_100%)] border-white text-white"
                                            : `border-black text-black`,
                                        )}
                                        onClick={(e) => {setColorButton(true); setColorPickerVisible(false); setPickerColor("");}}
                                      >
                                        Use brand colors
                                      </button>
                                    </div>
                                     
                                    </div>
                                  )}
                                  {isLastNonChatGPT && (colorButton || colorPickerVisible) && (
                                   <>
                                    <div className="flex gap-3 mb-4 text-black text-sm flex-1 float-left clear-both w-[70%]">
                                    <span className="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
                                      <div className="rounded-full bg-gray-100 border p-1">
                                        <img src="/favicon/favicon.ico" alt="" />
                                      </div>
                                    </span>
                                    <p className="leading-relaxed bg-[#E3E3E3] rounded-lg py-4 px-3">
                                    Click on a color to change the text.
                                    </p>
                                    </div>
                                    <div className="flex gap-3 mb-4 text-black text-sm flex-1 float-left clear-both w-[70%]">
                                    <span className="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
                                      <div className="rounded-full bg-gray-100 border p-1">
                                        <img src="/favicon/favicon.ico" alt="" />
                                      </div>
                                    </span>
                                    <p className="leading-relaxed bg-[#E3E3E3] rounded-lg py-4 px-3">
                                    You can click on different choices to see how each color looks.
                                    </p>
                                    </div>
                                  {isLastNonChatGPT && colorButton && (
                                    <div className="ml-11 border border-gray-300 p-4 rounded-lg clear-both w-[81%]">
                                      <div className="flex gap-2 mb-4 text-black text-sm clear-both w-[81%]">
                                        <div>
                                          {websiteColor.map((colors, index) => (
                                            <button
                                              key={index}
                                              style={{
                                                backgroundColor: colors.color,
                                                color: "white",
                                                fontSize: "12PX",
                                              }}
                                              className={cx("font-semibold mt-1 text-sm py-2 px-4 rounded inline-flex items-center mr-1 w-20 h-10 border border-2 rounded-xl",selectedBrandColor === colors.color ? "border-black" :"border-[#CECECE]" )}
                                              // onClick={() => handleColorClick(colors)}
                                              onClick={(e) => {
                                                setSelectedBrandColor(colors.color);
                                                handleButtonClick(colors.color);
                                                setBrandColor(true);
                                              }}
                                            >
                                            </button>
                                          ))}
                                        </div>
                                      </div>
                                      {isLastNonChatGPT && colorButton && brandColor && (
                                      <div className="flex gap-3 mb-4 text-black text-sm flex-1 clear-both w-[80%]">
                                        {/* <button
                                          className="font-semibold mt-1 text-xs py-2 px-4 rounded inline-flex items-center mr-1 bg-green-700 text-white"
                                          onClick={(e) => {setButtonModal(true); }}
                                        >
                                          Apply color
                                        </button> */}
                                        <button
                                        className="font-semibold mt-1 text-xs py-2 px-4 rounded inline-flex items-center mr-1 bg-green-700 text-white"
                                        onClick={() => {
                                          if (elementType === 'button' || elementType === 'text') {
                                            setButtonModal(true);
                                          } else {
                                            setPickerColor("");
                                            setColorPickerVisible(false);
                                            setColorOption(false);
                                            // Add the message regardless of the elementType
                                          setMessages((prevMessages) => [
                                            ...prevMessages,
                                            { message: "Attempted", sender: "ChatGPT" }
                                          ]);
                                          }

                                          
                                        }}
                                      >Apply color</button>
                                        <button
                                          className="font-semibold mt-1 text-xs py-2 px-4 rounded inline-flex items-center mr-1 bg-gray-600 text-white"
                                          onClick={(e) => {keepCurrentColor(); setColorButton(false); setBrandColor(false); setColorOption(false);}}
                                        >
                                          Keep current color
                                        </button>
                                      </div>
                                      )}
                                    </div>
                                  )}
                                    </>
                                  
                                  )}
                                  
                                </React.Fragment>
                              )}
                            </div>
                          );
                        })}

                        {loader && (
                          <div className="flex gap-3 my-4 text-sm flex-1 float-left clear-both w-[70.5%]">
                            <div className="scrapper-gif-image">
                              <img
                                src="/images/new_theme/Finalize-Loading-Animation.gif"
                                className="h-12"
                                alt="animation loader"
                              />
                            </div>
                          </div>
                        )}

                        {button ? (
                          <>
                            <div className="flex gap-3 mb-4 justify-center flex-1 clear-both">
                              <button
                                style={{
                                  backgroundColor: "green",
                                  color: "white",
                                  fontSize: "12PX",
                                }}
                                className=" font-semibold mt-1 text-sm py-2 px-4 rounded inline-flex items-center mr-1"
                                onClick={(e) => handleButtonClick("green")}
                              >
                                Green
                              </button>
                              <button
                                style={{
                                  backgroundColor: "black",
                                  color: "white",
                                  fontSize: "12PX",
                                }}
                                className=" font-semibold mt-1 text-sm py-2 px-4 rounded inline-flex items-center mr-1"
                                onClick={(e) => handleButtonClick("black")}
                              >
                                Black
                              </button>
                              <button
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                  fontSize: "12PX",
                                }}
                                className=" font-semibold mt-1 text-sm py-2 px-4 rounded inline-flex items-center"
                                onClick={(e) => handleButtonClick("red")}
                              >
                                Red
                              </button>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {style ? (
                          <>
                            <div className="mb-4 justify-center clear-both">
                              <button
                                className=" menu-button text-white text-sm font-semibold py-2 px-4 mt-1 rounded inline-flex items-center mr-1"
                                style={{
                                  fontSize: "12px",
                                  backgroundColor: "#0d99ff",
                                }}
                                onClick={() => handleSelectChange("header")}
                              >
                                Header
                              </button>
                              {/* <button
                                className=" menu-button bg-gray-300 text-white text-sm font-semibold py-2 px-4 mt-1 rounded inline-flex items-center mr-1"
                                style={{ fontSize: "12PX" }}
                              >
                                About
                              </button> */}
                              <button
                                className=" menu-button text-white text-sm font-semibold py-2 px-4 mt-1 rounded inline-flex items-center mr-1"
                                style={{
                                  fontSize: "12px",
                                  backgroundColor: "#0d99ff",
                                }}
                                onClick={() => handleSelectChange("benefits")}
                              >
                                Benefits
                              </button>
                              <button
                                className=" menu-button bg-gray-300 text-white text-sm font-semibold py-2 px-4 mt-1 rounded inline-flex items-center mr-1"
                                style={{
                                  fontSize: "12px",
                                  backgroundColor: "#0d99ff",
                                }}
                                onClick={() => handleSelectChange("howItWork")}
                              >
                                How it works
                              </button>
                              <button
                                className=" menu-button bg-gray-300 text-white text-sm font-semibold py-2 px-4 mt-1 rounded inline-flex items-center mr-1"
                                style={{
                                  fontSize: "12px",
                                  backgroundColor: "#0d99ff",
                                }}
                                onClick={() => handleSelectChange("service")}
                              >
                                Service
                              </button>
                              <button
                                className=" menu-button bg-gray-300 text-white text-sm font-semibold py-2 px-4 mt-1 rounded inline-flex items-center mr-1"
                                style={{
                                  fontSize: "12px",
                                  backgroundColor: "#0d99ff",
                                }}
                                onClick={() => handleSelectChange("review")}
                              >
                                Review
                              </button>
                              <button
                                className=" menu-button bg-gray-300 text-white text-sm font-semibold py-2 px-4 mt-1 rounded inline-flex items-center mr-1"
                                style={{
                                  fontSize: "12px",
                                  backgroundColor: "#0d99ff",
                                }}
                                onClick={() => handleSelectChange("faq")}
                              >
                                Faq
                              </button>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {colorPickerVisible && (
                          <div className="ml-11 border border-gray-300 p-4 rounded-lg clear-both w-[70%]">
                            <div>
                              <HexColorPicker
                                className="m-auto"
                                color={pickerColor ? pickerColor : "#4978c1"}
                                onChange={(pickerColor) => {
                                  setPickerColor(pickerColor);
                                }}

                              />
                            </div>
                            <div className="text-center">
                              <div className="flex my-2 justify-center">
                                <HexColorInput className={`text-xs border border-2 px-4 py-2 text-black whitespace-no-wrap w-24`} color={pickerColor ? pickerColor : "#4978c1"} onChange={(pickerColor) => {
                                  setPickerColor(pickerColor);
                                }} />
                                <span
                                  className={`text-xs border border-2 px-4 py-2 text-white whitespace-no-wrap w-24`}
                                  style={{ backgroundColor: pickerColor ? pickerColor : "#4978c1" }}
                                ></span>
                              </div>
                                
                              {pickerColor && (
                                 <div>
                                 <button
                                   className="font-semibold mt-1 text-xs py-2 px-4 rounded inline-flex items-center mr-1 bg-green-700 text-white"
                                  //  onClick={(e) => {setPickerColor(""); setColorPickerVisible(false); setColorOption(false); setButtonModal(true); setMessages((prevMessages) => [...prevMessages,{
                                  //   message:"Attempted",
                                  //   sender: "ChatGPT",
                                  // }]);}}
                                  onClick={() => {
                                    if (elementType === 'button'|| elementType === 'text') {
                                      setButtonModal(true);
                                    } else {
                                      setPickerColor("");
                                      setColorPickerVisible(false);
                                      setColorOption(false);
                                      // Add the message regardless of the elementType
                                    setMessages((prevMessages) => [
                                      ...prevMessages,
                                      { message: "Attempted", sender: "ChatGPT" }
                                    ]);
                                    }

                                    
                                  }}
                                  
                                 >
                                   Apply color
                                 </button>
                                 <button
                                   className="font-semibold mt-1 text-xs py-2 px-4 rounded inline-flex items-center mr-1 bg-gray-600 text-white"
                                   onClick={(e) => {keepCurrentColor(); setPickerColor(""); setColorPickerVisible(false); setColorOption(false);}}
                                 >
                                   Keep current color
                                 </button>
                               </div>
                              )}
                             
                            </div>
                          </div>
                        )}

                        {section &&  (
                          <>
                            <div className="flex gap-3 mb-4 justify-center flex-1 clear-both">
                              {/* <button
                                className="border text-[10px] rounded p-2.5 w-full border-black text-black hover:border-white hover:bg-[linear-gradient(135deg,_#02BFD5_0%,_#2688D1_29.07%,_#F92672_100%)] transition-all duration-300"
                                onClick={(e) => handleSectionClick("pricing")}
                              >
                                Add a Table Section
                              </button> */}
                              <button
                                className="border text-[10px] rounded p-2.5 w-full border-black text-black hover:border-white hover:bg-[linear-gradient(135deg,_#02BFD5_0%,_#2688D1_29.07%,_#F92672_100%)] transition-all duration-300"
                                onClick={(e) => handleSectionClick("gallery")}
                              >
                                Add an Image/Video Gallery
                              </button>
                              <button
                                className="border text-[10px] rounded p-2.5 w-full border-black text-black hover:border-white hover:bg-[linear-gradient(135deg,_#02BFD5_0%,_#2688D1_29.07%,_#F92672_100%)] transition-all duration-300"
                                onClick={(e) => handleSectionClick("partners")}
                              >
                                Add a Logo Section
                              </button>
                            </div>
                          </>
                        )}
                        {visible && style && (
                          <div className="px-2 py-6 clear-both">
                            <Accordion expanded={expanded}>
                              <AccordionDetails className="bg-[#fff]">
                                <button
                                  className="menu-button bg-[#0d99ff] text-white font-semibold text-sm py-2 px-4 mt-1 rounded inline-flex items-center mb-1"
                                  style={{ fontSize: "12PX" }}
                                  onClick={() => handelRequest()}
                                >
                                  Apply
                                </button>
                                <img
                                  src={images?.imageUrl}
                                  alt={"Img"}
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleImageClick("index")}
                                  onDoubleClick={() =>
                                    handleImageDoubleClick("index")
                                  }
                                />
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        )}
                      </div>

                      <div className="flex items-center pt-2.5">
                        <form
                          className="flex items-center justify-center w-full"
                          onSubmit={(event) => event.preventDefault()}
                        >
                          <input readOnly={!getSelected?true:false}
                            className="flex resize-none h-[42px] w-full rounded-l-2xl border border-r-0  border-[#e5e7eb] px-3 py-2 text-sm placeholder-[#6b7280] focus:outline-none  text-[#030712] focus-visible:ring-offset-2"
                            type="text"
                            id="chatInputField"
                            name="chatInputField"
                            placeholder="Type your message"
                            value={chatInputValue}
                            onChange={(e) => {
                              setChatInputValue(e.target.value);
                            }}
                            onFocus={() => {
                              // setShowListOnHover(false);
                              setStyle(false);
                              setButton(false);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter"  && chatInputValue) {
                                handleSendRequest();
                              }
                            }}
                          />
                          <div className="inline-flex items-center justify-center rounded-r-2xl text-sm font-medium text-[#f9fafb] border border-l-0 border-[#e5e7eb] h-[42px] w-[64px] py-2 px-[15px]">
                            <img
                              onClick={
                                chatInputValue
                                  ? () => handleSendRequest()
                                  : undefined
                              }
                              src="/images/new_theme/chatButton.svg"
                              className={`w-[28px] h-[28px] ${
                                chatInputValue ? "" : "opacity-75"
                              }`}
                              alt=""
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
          {/* )} */}
        </div>
      )}
      <div className="chat-icon new-icon" onClick={toggleChatbox}>
        {visibility ? (
          ""
        ) : getSelected && getSelected.get("type") !== "image" ? (
          <Box>
            <img
              src="/images/new_theme/message-question.svg"
              alt="message-question"
            />
          </Box>
        ) : (
          ""
        )}
      </div>

      {buttonModal && (
        <div className=" edit-moadal absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 ">
        <div className="bg-[white] rounded-xl relative w-[484px] ">
          <div className="flex justify-end pe-2 pt-2">
            <button
              className="outline outline-black outline-1 rounded-full"
              type="button"
              onClick={() => {
                setButtonModal(false);
              }}
            >
              <Icon size={0.9} path={mdiClose} />
            </button>
          </div>
          <div className="flex justify-center">
          <span className="text-[20px] text-black font-bold leading-6" >
            {elementType === 'text' ? (
              (() => {
                const data = getStyleProperties(styleData);
                return headerClasses.includes(data[0].className)
                  ? `Have AI apply color to all relevant items`
                  : `Have AI apply color to all relevant text`;
              })()
            ) : "Have AI apply color to all the relevant buttons"}
          </span>
          </div>
          <div className="px-5 pt-6">
            <div className="text-center mb-10">
              <button
                type="button"
                className="rounded text-[12px] outline outline-1 outline-black text-center font-medium w-[79px] h-[27px] text-black btn-bg hover:outline-none hover:text-white"
                onClick={async () => {
                  setButtonModal(false);
                  setButtonAction(true);
                  handleAllColor(); // Wait for handleAllColor to complete
                  setMessages((prevMessages) => [
                    ...prevMessages,
                    { message: "Attempted", sender: "ChatGPT" }
                  ]);
                  
                }}
              >
               Yes
              </button>
              <button
                type="button"
                className="ms-2 outline outline-1 outline-black rounded leading-[15.08px] text-[12px] text-center h-[27px] font-medium px-2 text-black btn-bg hover:outline-none hover:text-white"
                onClick={() => {
                  setColorApplyType("single");
                  setButtonModal(false);
                  setButtonAction(false);
                  setButtonModal(false);
                  setActionType("");
                  setColorButton(false);
                  setColorPickerVisible(false);
                  setButton(false);
                  setColorOption(false);
                  setMessages((prevMessages) => [
                    ...prevMessages,
                    { message: "Attempted", sender: "ChatGPT" }
                  ]);
                }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
      )}
      {openAddTagModal && (
        <CustomAddTagModal setOpenAddTagModal={setOpenAddTagModal} />
      )}
       {openEditPagePropModal && (
        <CustomEditPagePropModal setOpenEditPagePropModal={setOpenEditPagePropModal} />
      )}
      {isDNSModalOpen && (
        <CustomAddDomainModal
          setIsModalOpen={setIsModalOpen}
          setUseDomainModal={setUseDomainModal}
          publish_url = {publishUrl}
        />
      )}
      {useDomainModal && (
        <CustomUseDomainModal
          setUseDomainModal={setUseDomainModal}
          publish_url = {publishUrl}
        />
      )}
    </>
  );
}