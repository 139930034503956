import React, { useEffect, useRef, useState } from "react";
import { mdiClose, mdiMenuDown, mdiMenuUp } from "@mdi/js";
import Icon from "@mdi/react";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";

const CustomButtonLinkModal = ({ setOpenButtonLinkModal, ...props }) => {
  const dispatch = useDispatch();
  const [url, setUrl] = useState("");
  const customSelectWrapper = useRef(null);
  const customActionSelectWrapper = useRef(null);
  const [isActionOpen, setIsActionOpen] = useState(false);
  const [isTargetOpen, setIsTargetOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState("Go to Url");
  const [selectedTarget, setSelectedTarget] = useState("Select");
  const optionsList = ["Same tab or window", "New tab or window"];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        customSelectWrapper.current &&
        !customSelectWrapper.current.contains(event.target)
      ) {
        setIsTargetOpen(false);
      }
      if (
        customActionSelectWrapper.current &&
        !customActionSelectWrapper.current.contains(event.target)
      ) {
        setIsActionOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleActionToggle = () => {
    setIsActionOpen(!isActionOpen);
  };

  const handleActionOptionClick = (option) => {
    setSelectedAction(option);
    setIsActionOpen(false);
  };

  const handleTargetToggle = () => {
    setIsTargetOpen(!isTargetOpen);
  };

  const handleTargetOptionClick = (option) => {
    setSelectedTarget(option);
    setIsTargetOpen(false);
  };

  useEffect(() => {
    let selectedComponent = window.editor.getSelected();
    if (
      selectedComponent.get("type") === "image" &&
      selectedComponent.getClasses().includes("social_icon")
    ) {
      const parent = selectedComponent.parent();
      selectedComponent = parent;
    }

    const attributes = selectedComponent.getAttributes();
    if (attributes.href && attributes.href !== "#") {
      setUrl(attributes.href);
      attributes.target && attributes.target === "_blank" ? setSelectedTarget("New tab or window") : setSelectedTarget("Same tab or window");
    }
  }, []);

  const save = async () => {
    if (!url) {
      toast.error("URL is Required");
      return;
    }
    const isUrlValid = isValidUrl(url);
    if (!isUrlValid) {
      toast.error("Invalid URL. Use format: http://example.com");
      return;
    }

    if (selectedTarget === "Select") {
      toast.error("Select Target");
      return;
    }

    const selectedComponent = window.editor.getSelected();
    if (
      selectedComponent.get("type") === "link" ||
      (selectedComponent.get("type") === "image" &&
        selectedComponent.getClasses().includes("social_icon"))
    ) {
      let targetComponent = selectedComponent;

      if (selectedComponent.get("type") === "image") {
        const parent = selectedComponent.parent();
        targetComponent = parent;
      }

      if (targetComponent.get("type") === "link") {
        const attrb = { href: url };
        if (selectedTarget === "New tab or window") {
          attrb.target = "_blank";
        } else {
          targetComponent.removeAttributes("target");
        }
        targetComponent.addAttributes(attrb);
      }
    }
    dispatch({ type: "EDITOR_CHANGE_STATUS", payload: true });
    setOpenButtonLinkModal(false);
  };

  function isValidUrl(urlString) {
    try {
      // Try to create a new URL object with the given string
      new URL(urlString);
      return true; // If no error is thrown, the URL is valid
    } catch (error) {
      return false; // If an error is thrown, the URL is invalid
    }
  }

  return (
    <div className="edit-moadal absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white pt-4 px-[30px] rounded relative w-[1200px]">
        <div className="flex justify-between">
          <div className="ps-[14px] pt-6">
            <span className="font-bold text-[20px] leading-[21px] text-black">
              Click Action
            </span>
          </div>
          <button
            type="button"
            onClick={() => {
              setOpenButtonLinkModal(false);
            }}
          >
            <Icon size={1.5} path={mdiClose} color={"black"} />
          </button>
        </div>
        <div className="border border-[#959595] rounded-xl my-10 px-5 py-6">
          <div className="flex gap-4">
            <div className="flex flex-col">
              <span className="font-bold text-[15px] leading-[18.85px] text-black">
                Action
              </span>
              <div
                className={`custom-select-wrapper w-[307px] mt-2`}
                ref={customActionSelectWrapper}
              >
                <div
                  className={`flex justify-between text-[15px] text-black leading-5 font-medium py-[10px] px-[12px] cursor-pointer outline outline-1 ${
                    isActionOpen
                      ? "rounded-t-lg outline-[#000000]"
                      : "rounded-lg outline-[#959595]"
                  }`}
                  onClick={handleActionToggle}
                >
                  <span
                    className={`${
                      selectedAction.includes("Select")
                        ? "text-[#A0AEC0]"
                        : "text-[#000000]"
                    }`}
                  >
                    {selectedAction}
                  </span>
                  {!isActionOpen ? (
                    <span aria-label="arrow">
                      <Icon size={0.9} path={mdiMenuDown} color={"black"} />
                    </span>
                  ) : (
                    <span aria-label="arrow">
                      <Icon size={0.9} path={mdiMenuUp} color={"black"} />
                    </span>
                  )}
                </div>
                {isActionOpen && (
                  <div
                    className="absolute bg-[#262626] text-[white] z-10 w-[inherit] rounded-b max-h-[200px] overflow-auto"
                    onMouseLeave={handleActionToggle}
                  >
                    <div
                      className="custom-option border-b border-[#1F1F1F] cursor-pointer py-[10.75px] ps-4 text-[10px] font-medium hover:bg-[black]"
                      onClick={() => handleActionOptionClick("Go to Url")}
                      data-value="1"
                    >
                      Go to Url
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col">
              <span className="font-bold text-[15px] leading-[18.85px] text-black">
                Url
              </span>
              <div>
                <input
                  type="text"
                  className=" mt-2 w-[307px] outline outline-1 outline-[#959595] rounded-lg px-[12px] py-[10px] font-medium text-[15px] text-black leading-5 placeholder:font-medium placeholder:text-[15px] placeholder:leading-5"
                  name="field_value"
                  placeholder="content"
                  autoComplete="off"
                  onChange={(e) => {
                    setUrl(e.target.value);
                  }}
                  value={url}
                />
              </div>
            </div>

            <div className="flex flex-col">
              <span className="font-bold text-[15px] leading-[18.85px] text-black">
                Target
              </span>
              <div
                className={`custom-select-wrapper w-[307px] mt-2`}
                ref={customSelectWrapper}
              >
                <div
                  className={`flex justify-between text-[15px] text-black leading-5 font-medium py-[10px] px-[12px] cursor-pointer outline outline-1 ${
                    isTargetOpen
                      ? "rounded-t-lg outline-[#000000]"
                      : "rounded-lg outline-[#959595]"
                  }`}
                  onClick={handleTargetToggle}
                >
                  <span
                    className={`${
                      selectedTarget.includes("Select")
                        ? "text-[#A0AEC0]"
                        : "text-[#000000]"
                    }`}
                  >
                    {selectedTarget}
                  </span>
                  {!isTargetOpen ? (
                    <span aria-label="arrow">
                      <Icon size={0.9} path={mdiMenuDown} color={"black"} />
                    </span>
                  ) : (
                    <span aria-label="arrow">
                      <Icon size={0.9} path={mdiMenuUp} color={"black"} />
                    </span>
                  )}
                </div>
                {isTargetOpen && (
                  <div
                    className="absolute bg-[#262626] text-[white] z-10 w-[inherit] rounded-b max-h-[200px] overflow-auto"
                    onMouseLeave={handleTargetToggle}
                  >
                    {optionsList?.map((option) => (
                      <div
                        className="custom-option border-b border-[#1F1F1F] cursor-pointer py-[10.75px] ps-4 text-[10px] font-medium hover:bg-[black]"
                        onClick={() => handleTargetOptionClick(option)}
                        data-value="1"
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="text-end mt-10">
            <button
              className=" w-[82px] h-[32px] py-[5px] px-5 text-white font-medium text-[12px] rounded-[6px] outline outline-1 outline-[#2688D1] bg-[#2688D1]"
              type="button"
              onClick={save}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2000} pauseOnHover={false} theme="colored" />
    </div>
  );
};

export default CustomButtonLinkModal;
