import { authApi } from './AxiosInstance';
const API_URL = process.env.REACT_APP_API_URL;
  
const getImagesFromS3 = (data) => {
    return authApi.post(API_URL + "s3Bucket/getS3Images",{
      data,
    });
  };

const uploadImagesToS3 = (file,folder_name,file_name) => {
  return authApi.post(API_URL + "s3Bucket/uploadImageToS3", {
    file,
    folder_name,
    file_name
  });
};




const s3BucketService = {
  uploadImagesToS3,
  getImagesFromS3,
}

export default s3BucketService;