import axios from 'axios';
import { jwtDecode } from "jwt-decode";


const API_URL = process.env.REACT_APP_API_URL;
export const apiUrl = process.env.REACT_APP_API_URL;
export const authApi = axios.create({
  baseURL: apiUrl
});

const authAp = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});


authApi.interceptors.request.use(
  async (config) => {
    // console.log(localStorage.getItem('token'));
    const token = JSON.parse(localStorage.getItem('token'));
    if (token) {
        const decoded = jwtDecode(token);
        let tokenExpire = decoded.exp
        let currentDate = (Date.now()/1000)
        if(currentDate > tokenExpire ){
          
          const refreshToken = JSON.parse(localStorage.getItem('refreshToken'));
          if (refreshToken) {
            const refreshResponse = await axios.post(`${API_URL}auth/refresh-tokens`, {
              refreshToken: refreshToken,
            });
          if (refreshResponse.data) {
            const newAccessToken = refreshResponse.data.access.token;
            localStorage.setItem('token', JSON.stringify(newAccessToken));
            localStorage.setItem('refreshToken', JSON.stringify(refreshResponse.data.refresh.token));
            config.headers.Authorization = `Bearer ${newAccessToken}`;
            return config;
          }      
        }} else{
          config.headers.Authorization = `Bearer ${token}`;
          return config;

        }
      } 
  },
  (error) => {
    return Promise.reject(error);
  }
);

authApi.interceptors.response.use(
  (response) => {
    // console.log('responce refersh');
    return response;
  },
  async (error) => {

    console.log(error);
    

    const originalRequest = error.config;

    if (error.code === 'ERR_BAD_RESPONSE' || (error.response.data.code === 401 && !originalRequest._retry)) {
    if(originalRequest.url ===  `/users/load/user`){
      localStorage.clear();
      window.location.href = '/login';
    } 

    // if(originalRequest.url !== API_URL + `users/load/user` ) { 
    //       const refreshResponse = await axios.post(`${API_URL}auth/refresh-tokens`, {
    //         refreshToken: localStorage.getItem('refreshToken'),
    //       });}


          // console.log(refreshResponse,'refreshResponse');
// if(refreshResponse?.data?.tokens?.access?.token){
//   console.log('sdfsdfsdfsdfsd ');
//           localStorage.setItem('token', refreshResponse?.data?.tokens?.access?.token);
//           originalRequest.headers.Authorization = `Bearer ${refreshResponse?.data?.tokens?.access?.token}`;
// console.log(originalRequest, 'originalRequest');
//           return authApi(originalRequest);  
// }     
try {
  const refreshToken = JSON.parse(localStorage.getItem('refreshToken'));
  if (refreshToken) {
    const refreshResponse = await axios.post(`${API_URL}auth/refresh-tokens`, {
      refreshToken: refreshToken,
    });
    // AuthService.saveTokenInLocalStorage(refreshToken.data, moment(refreshToken?.data?.tokens?.access?.expires).valueOf());
    if (refreshResponse.data && refreshResponse.data) {
      const newAccessToken = refreshResponse.data.access.token;
      localStorage.setItem('token', JSON.stringify(newAccessToken));
      localStorage.setItem('refreshToken', JSON.stringify(refreshResponse.data.refresh.token));

      // Update the original request with the new access token
      originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

      // Retry the original request
      return authAp(originalRequest);
    }
  }

      } catch (refreshError) {
        const token = JSON.parse(localStorage.getItem('user'));
        if (token) {
          localStorage.clear();
        }
        window.location.href = '/login';
        }
    }
  }
);

