import { authApi } from './AxiosInstance';
const API_URL = process.env.REACT_APP_API_URL;
  
const scraper1 = (data) => {
    return authApi.post(API_URL + "scrapai/", {
      inputField : data.inputField,
      userId : data.userId
    });
  };

  const scraperData = (id, step) => {
    return authApi.post(`${API_URL}scrapai/${id}`, {
      status: step,
    });
  };

  const updateScraperData = (id,data) => {
    return authApi.post(`${API_URL}scrapai/update/${id}`, {
      type: data.type,
      heroImage: data.heroImage ? data.heroImage : "",
      logo: data.logo ? data.logo : "",
      colors: data.colors ? JSON.parse(data.colors) : "",
    });
  };

  const autoSkip = (id,data) => {
    return authApi.post(`${API_URL}scrapai/auto-skip/${id}`, {
      templateId: data.templateId,
      userId: data.userId,
      id: data.id,

    });
  };


const scraperjsService = {
  scraper1,
  scraperData,
  updateScraperData,
  autoSkip,
}

export default scraperjsService;