import { SECTION_ADD_STATUS, EDITOR_CHANGE_STATUS,LOGIN_SUCCESS, LOGIN_FAILURE, REGISTER_FAILURE, REGISTER_SUCCESS, GOOGLE_LOGIN_FAILURE, GOOGLE_LOGIN_SUCCESS, FORGET_PASSWORD_SUCCESS, FORGET_PASSWORD_FAILURE, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE, EDITOR_PUBLISH_STATUS, CREATE_SESSION_BY_ADMIN, GET_USERS_EMAIL_FAILURE } from "../actions/type";

const auth_user = JSON.parse(localStorage.getItem("user"));
if(auth_user){
  var user = auth_user
}

const initialState = {
  editor_change_status: false,
  editor_publish_status: false,
  section_add_status: false,
  setTimeDifference: null,
  ...(user ? { isLoggedIn: true, user } : { isLoggedIn: false, user: null })
};



export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: action.payload,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        login: action.payload,
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        register: action.payload,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        register: action.payload,
      };
    case GOOGLE_LOGIN_FAILURE:
      return {
        ...state,
        googleLogin: action.payload,
      };
    case GOOGLE_LOGIN_SUCCESS:
      return {
        ...state,
        googleLogin: action.payload,
      };
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        forgetPassword: action.payload,
      };
    case FORGET_PASSWORD_FAILURE:
      return {
        ...state,
        forgetPassword: action.payload,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: action.payload,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPassword: action.payload,
      };
      case EDITOR_CHANGE_STATUS:
        return {
          ...state,
          editor_change_status:action.payload
        };
        case EDITOR_PUBLISH_STATUS:
        return {
          ...state,
          editor_publish_status:action.payload
        };
      case SECTION_ADD_STATUS:
        return {
          ...state,
          section_add_status:action.payload
        };
        case "SET_TIME_DIFFERENCE":
        return {
          ...state,
          setTimeDifference:action.payload
        };
      case CREATE_SESSION_BY_ADMIN:
        return {
          ...state,
          createSessionByAdmin:action.payload
        };
        case GET_USERS_EMAIL_FAILURE:
          return {
            ...state,
            getUsersEmail:action.payload
          };
        case "GET_USERS_EMAIL_SUCCESS":
          return {
            ...state,
            getUsersEmail:action.payload
          };
    default:
      return state;
  }
}
