import React, { useState } from "react";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { ButtonLoaderJoxy } from "./LoaderJoxy";
import { useDispatch } from "react-redux";
import { addNewSection } from "../../actions/grapesjs";

const CustomAddSectionModal = ({ setOpenAddSectionModal, ...props }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [sectionType, setSectionType] = useState("gallery");
  const [sectionIndex, setSectionIndex] = useState("");
  const [selectedTableSection, setSelectedTableSection] = useState(null);
  const [selectedGallerySection, setSelectedGallerySection] = useState(null);
  const [selectedPatnersSection, setSelectedPatnersSection] = useState(null);
  const setSectionTypeArray = ['gallery', 'pricing', 'partners']
  const editor = window.editor;
  const getSelected = editor.getSelected();
  const TableImages = [
    "https://joxy-template.s3.amazonaws.com/screen_images/Us+Vs+Them.png",
    "https://joxy-template.s3.amazonaws.com/screen_images/Us+Vs+Them(1).png",
  ];

  const GalleryImages = [
    "https://joxy-template.s3.amazonaws.com/screen_images/Our Gallery.png",
    "https://joxy-template.s3.amazonaws.com/screen_images/Our Gallery(1).png",
    "https://joxy-template.s3.amazonaws.com/screen_images/Our Gallery(2).png",
    "https://joxy-template.s3.amazonaws.com/screen_images/Our Gallery(3).png",
  ];

  const PatnersImages = [
    "https://joxy-template.s3.amazonaws.com/screen_images/Button.png",
    "https://joxy-template.s3.amazonaws.com/screen_images/Button(1).png",
    "https://joxy-template.s3.amazonaws.com/screen_images/Button(2).png",
  ];

  const handleSelectedSection = (index, sectionType) => {
    if (sectionType === "pricing") {
      setSectionIndex(index);
      setSelectedTableSection(index === selectedTableSection ? null : index);
    }
    if (sectionType === "gallery") {
      setSectionIndex(index);
      setSelectedGallerySection(
        index === selectedGallerySection ? null : index
      );
    }
    if (sectionType === "partners") {
      setSectionIndex(index);
      setSelectedPatnersSection(
        index === selectedPatnersSection ? null : index
      );
    }
  };

  const closeSectionModal = () => {
    setOpenAddSectionModal(false);
  };
  const handleAddSection = (name) => {
    setIsLoading(true);
    dispatch(addNewSection({ name }))
      .then((response) => {
        const classData = getSelected.getClasses();
        // Find all components with the class 'my-class'
        const componentsWithClass = window.editor
          .getWrapper()
          .find(`.${classData[0]}`);

        // Filter to get only 'div' elements
        const divComponents = componentsWithClass.filter(
          (comp) => comp.get("tagName") === "div"
        );

        if (divComponents.length) {
          const targetDiv = divComponents[0]; // Assuming you want the first div with the class

          // Create a new component to hold the HTML content
          // const newComponent = window.editor.DomComponents.addComponent({
          //   tagName: 'div', // or whatever tag you want to use
          //   content: response.data.html,
          // });

          const parent = targetDiv.parent();
          parent.append(response.data.html, {
            at: targetDiv.index(), // Move the newComponent before targetDiv
          });
          // Apply the CSS rules
          editor.Css.addRules(response.data.css);
          setTimeout(() => {
            dispatch({ type: "SECTION_ADD_STATUS", payload: false });
            dispatch({ type: "EDITOR_CHANGE_STATUS", payload: true });
            setOpenAddSectionModal(false);
            setIsLoading(false);
            setSectionType("");
          }, 3000);
        } else {
          console.log('No div with class "my-class" found.');
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <div className=" edit-moadal absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white px-7 py-10 rounded relative w-[1200px]">
        <div className="flex justify-between">
          <h2 className="text-[20px] text-black">Choose a Section</h2>
          <button
            type="button"
            onClick={() => {
              closeSectionModal();
            }}
            className="text-[20px] text-black"
          >
            <Icon size={1} path={mdiClose} />
          </button>
        </div>
        <div>
          <div className="flex gap-x-5 mt-4">
            {setSectionTypeArray.map((type, index) => (
              <button
                key={index}
                onClick={() => setSectionType(type)}
                className={`rounded-lg text-[12px] outline outline-1 outline-[#1A1A1A2E] text-center text-black font-medium w-[70px] h-[32px] transition-all duration-300 capitalize  ${
                  sectionType === type? "hexbox-button text-white" : ""
                }`}
              >
                {type}
              </button>
            ))}
          </div>
        </div>
        <div className="flex mt-10 w-[1140px] h-[446px]">
          <div className="outline outline-1 outline-[#959595] rounded-xl overflow-y-auto w-[100%] px-10 pt-8">
            <div className="flex gap-x-5 h-[345px]">
              {sectionType === "pricing" &&
                TableImages.slice(0, 2).map((src, index) => (
                  <>
                    <img
                      key={index}
                      onClick={() => handleSelectedSection(index, sectionType)}
                      className={`w-[296px] h-[356px] transform transition-transform duration-300 ${
                        selectedTableSection === index
                          ? "scale-110"
                          : "scale-100"
                      }`}
                      src={src}
                      alt={`TableImg ${index + 1}`}
                    />
                  </>
                ))}

              {sectionType === "gallery" &&
                GalleryImages.slice(0, 4).map((src, index) => (
                  <>
                    <img
                      key={index}
                      onClick={() => handleSelectedSection(index, sectionType)}
                      className={`w-[269px] h-[269px] transform transition-transform duration-300 ${
                        selectedGallerySection === index
                          ? "scale-110"
                          : "scale-100"
                      }`}
                      src={src}
                      alt={`GalleryImg ${index + 1}`}
                    />
                  </>
                ))}

              {sectionType === "partners" &&
                PatnersImages.slice(0, 3).map((src, index) => (
                  <>
                    <img
                      key={index}
                      onClick={() => handleSelectedSection(index, sectionType)}
                      className={`w-[338px] h-[55px] transform transition-transform duration-300 ${
                        selectedPatnersSection === index
                          ? "scale-110"
                          : "scale-100"
                      }`}
                      src={src}
                      alt={`LogoImg ${index + 1}`}
                    />
                  </>
                ))}
            </div>
            <div className="text-end mt-4">
              <button
                onClick={() => {
                  closeSectionModal();
                }}
                type="button"
                className="rounded-lg text-[12px] outline outline-1 outline-[#1A1A1A2E] text-center text-black font-medium w-[70px] h-[32px]"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  if (!isLoading) {
                    handleAddSection(sectionType + `_` + (sectionIndex + 1));
                  }
                }}
                type="button"
                className={
                  `ms-4 rounded-lg text-[12px] text-center font-medium text-white w-[109px] h-[32px] bg-[#2688D1] ` +
                  (isLoading ? "opacity-75" : "")
                }
              >
                {isLoading ? <ButtonLoaderJoxy /> : "Add Section"}
              </button>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomAddSectionModal;
