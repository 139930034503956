import React from "react";

const Footer = (currentUrl) => {
  // const [currentUrl, setCurrentUrl] = useState("");
  // useEffect(() => {
  //   setCurrentUrl(url);
  // }, [url]);
  return (
    <div>
      <div
        // style={{
        //   bottom: 0,
        //   position: "fixed",
        //   width: "100%",
        // }}
        className={`mx-auto bg-black p-4
         ${currentUrl.url === "/website-list" ? " " : "Joxyfooter"}`}
      >
        {/* "mx-auto bg-black p-4 Joxyfooter"> */}
        <div className="grid  grid-cols-3 gap-6">
          <div className="flex justify-center text-white lg:text-[14px] text-[8px]">
            Joxy.ai © 2024. All Rights Reserved
          </div>
          <div className="flex justify-center">
            <img src="/images/footerx.svg" alt="footer" />
            <img className="ms-4" src="/images/footerIn.svg" alt="footerIn" />
          </div>
          <div className="flex justify-center text-white lg:text-[14px] text-[8px]">
            <a href="/PrivacyPolicy" target="_blank">Privacy Policy </a> <span className="mx-2">|</span>  <a href="/TermsAndCondition" target="_blank">Terms of Conditions</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
