import { authApi } from "./AxiosInstance";
const API_URL = process.env.REACT_APP_API_URL;

const addScript = (data) => {
  return authApi.post(`${API_URL}script/add-script`, data);
};

const getScripts = (userId) => {
  return authApi.get(`${API_URL}script/getAll/${userId}`);
};

const getAppliedScriptsByWebsiteId = (websiteId, userId) => {
  return authApi.get(`${API_URL}script/get/${userId}/${websiteId}`);
};

const deleteScript = (id) => {
  return authApi.post(`${API_URL}script/delete/${id}`);
};

const editScripts = (data) => {
  return authApi.post(`${API_URL}script/edit-script`, data);
};
const addMetaData = (data) => {
  return authApi.post(`${API_URL}script/add-meta`, data);
};
const updateFavicon = (data) => {
  return authApi.post(`${API_URL}script/update-favicon`, data);
};
const getMetaData = (websiteId) => {
  return authApi.get(`${API_URL}script/get-meta/${websiteId}`);
};

const uploadSSLCertificate = (data) => {
  // authApi pass header multipart/form-data
  authApi.defaults.headers.post["Content-Type"] = "multipart/form-data";
  return authApi.post(`${API_URL}script/upload-ssl`, data);
};

const generateJoxySSL = (data) => {
  return authApi.post(`${API_URL}script/generate-joxy-ssl`, data);
};

const ScriptService = {
  addScript,
  getScripts,
  deleteScript,
  editScripts,
  addMetaData,
  updateFavicon,
  getMetaData,
  getAppliedScriptsByWebsiteId,
  uploadSSLCertificate,
  generateJoxySSL,
};

export default ScriptService;
