import React, { useEffect, useRef } from "react";
import { googleLogout } from "@react-oauth/google";
import { useLocation, useNavigate } from "react-router-dom";

const ProfileDropdown = ({ setAnchorEl, userDetail, open, setOpen, top }) => {
  // const [activeMenuItem, setActiveMenuItem] = useState(null);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const location = useLocation();

  const handleLogout = async () => {
    try {
      if (userDetail?.user?.clientId) {
        await googleLogout();
        localStorage.clear();
        navigate("/login");
      } else {
        localStorage.clear();
        navigate("/login");
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const handleClose = (menuItem) => {
    // setActiveMenuItem(menuItem);
    setAnchorEl(null);
    // Store selected menu item in localStorage
    localStorage.setItem("selectedMenuItem", menuItem);
    setOpen(false);
  };

  useEffect(() => {
    const current_route = location.pathname;
    if(current_route === "/"){
      handleClose('Dashboard');
    }
    if(current_route === "/website-list"){
      handleClose('My Projects');
    }
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {open && (
        <div
          id="account-menu"
          className="Menu w-[277px] absolute bg-white rounded-lg text-black"
          style={{
            right: "20px",
            boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
            top: top,
            zIndex: 999999,
          }}
          ref={menuRef}
        >
          <div style={{ borderBottom: "1px solid #ccc" }}>
            <div
              onClick={() => handleClose("Profile")}
              className="py-6 px-4 flex items-center cursor-pointer"
            >
              <img
                src="/images/new_theme/dummy_person.png"
                className="h-[49px] w-[49px] rounded-md mr-4 bg-[#86878D]"
                alt=""
              />

              <div>
                <p className="font-cera-pro-bold font-bold text-base leading-[20.11px] capitalize">
                  {" "}
                  {userDetail?.user?.name}
                </p>
                <p className="font-medium text-[10px] leading-[12.57px] text-[#999999]">
                  {userDetail?.user?.email}
                </p>
              </div>
            </div>
          </div>
          <div className="px-4 pt-2">
            <div
              onClick={() => {
                handleClose("Dashboard");
                navigate("/");
              }}
              className={`text-base leading-[20.11px] font-medium px-4 py-[11px] cursor-pointer mb-2 ${
                localStorage.getItem("selectedMenuItem") === "Dashboard"
                  ? "bg-[#f0f0f0] text-[#2688D1]"
                  : "hover:bg-[#F6F6F6]"
              }`}
            >
              Dashboard
            </div>
          </div>
          <div className="px-4 pt-2">
            <div
              onClick={() => {
                handleClose("My Domains");
                navigate("/my-domains");
              }}
              className={`text-base leading-[20.11px] font-medium px-4 py-[11px] cursor-pointer mb-2 ${
                localStorage.getItem("selectedMenuItem") === "My Domains"
                  ? "bg-[#f0f0f0] text-[#2688D1]"
                  : "hover:bg-[#F6F6F6]"
              }`}
            >
              My Domains
            </div>
          </div>
          {/* <div className="px-4 pt-2">
            <div
              onClick={() => {
                handleClose("Domain Instructions");
                navigate("/domain-instructions");
              }}
              className={`text-base leading-[20.11px] font-medium px-4 py-[11px] cursor-pointer mb-2 ${
                localStorage.getItem("selectedMenuItem") === "Domain Instructions"
                  ? "bg-[#f0f0f0] text-[#2688D1]"
                  : "hover:bg-[#F6F6F6]"
              }`}
            >
              Domain Instructions
            </div>
          </div> */}
            
          {/* <div className="px-4 pt-2">
            <div
              onClick={() => {
                handleClose("My account");
                navigate("/");
              }}
              className={`text-base leading-[20.11px] font-medium px-4 py-[11px] cursor-pointer mb-2 ${
                activeMenuItem === "My account"
                  ? "bg-[#f0f0f0] text-[#2688D1]"
                  : "hover:bg-[#F6F6F6]"
              }`}
            >
              My Account
            </div>
            <div
              onClick={() => {
                handleClose("Dashboard");
                navigate("/");
              }}
              className={`text-base leading-[20.11px] font-medium px-4 py-[11px] cursor-pointer mb-2  ${
                activeMenuItem === "Dashboard"
                  ? "bg-[#f0f0f0] text-[#2688D1]"
                  : "hover:bg-[#F6F6F6]"
              }`}
            >
              Dashboard
            </div>
            <div
              onClick={() => {
                handleClose("My Projects");
                navigate("/website-list");
              }}
              className={`text-base leading-[20.11px] font-medium px-4 py-[11px] cursor-pointer mb-2 ${
                activeMenuItem === "My Projects"
                  ? "bg-[#f0f0f0] text-[#2688D1]"
                  : "hover:bg-[#F6F6F6]"
              }`}
            >
              My Projects
            </div>
          </div>
          <hr></hr> */}
          <div className="px-4">
            <div
              onClick={() => {
                handleLogout();
                localStorage.removeItem("selectedMenuItem");
                localStorage.removeItem("subscriptionStatus");
              }}
              className={`text-base leading-[20.11px] font-medium px-4 py-[11px] cursor-pointer my-2 hover:bg-[#F6F6F6]`}
            >
              Sign Out
            </div>
            </div>
        </div>
      )}
    </>
  );
};

export default ProfileDropdown;
