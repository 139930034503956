import { useEffect } from "react";
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

export const PreventKeyPresses = () => {

  useEffect(() => {
    document.addEventListener("contextmenu", (event) => event.preventDefault());
    return () => {
      document.removeEventListener("contextmenu", (event) => event.preventDefault());
    };
  }, []);
  useEffect(() => {
    const handleKeyPress = (e) => {
      const key = e.key || e.keyCode;

      // Block common key combinations for accessing DevTools
      const blockedCombos = [
        { ctrl: true, shift: true, key: ['I', 'i'] }, // Ctrl+Shift+I
        { ctrl: true, shift: true, key: ['C', 'c'] }, // Ctrl+Shift+C
        { ctrl: true, shift: true, key: ['J', 'j'] }, // Ctrl+Shift+J
        { ctrl: true, shift: true, key: ['K', 'k'] }, // Ctrl+Shift+K
        { ctrl: true, key: ['U', 'u'] },             // Ctrl+U (View Source)
        { key: ['F12', 123] }                        // F12
      ];

      const isBlocked = blockedCombos.some(combo =>
        (combo.ctrl ? (e.ctrlKey || e.metaKey) : true) &&
        (combo.shift ? e.shiftKey : true) &&
        combo.key.includes(key)
      );

      if (isBlocked) {
        e.preventDefault();
      }
    };

    // Disable right-click context menu
    const disableContextMenu = (e) => e.preventDefault();

    // Detect if DevTools is open based on resizing
    const checkDevTools = () => {
      const devToolsOpen = window.outerHeight - window.innerHeight > 100 || window.outerWidth - window.innerWidth > 100;
      if (devToolsOpen) {
        disableReactDevTools(); // Disable React dev tools if open
      }
    };

    // Periodically check for open DevTools
    const devToolsInterval = setInterval(checkDevTools, 500);

    // Event listeners
    window.addEventListener('keydown', handleKeyPress);
    window.addEventListener('contextmenu', disableContextMenu);
    window.addEventListener('resize', checkDevTools);

    return () => {
      clearInterval(devToolsInterval);
      window.removeEventListener('keydown', handleKeyPress);
      window.removeEventListener('contextmenu', disableContextMenu);
      window.removeEventListener('resize', checkDevTools);
    };
  }, []);

  return null;
};
