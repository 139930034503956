import React from 'react';
import {
  SelectorsProvider,
} from '@grapesjs/react';
import CustomChatAIManager from './CustomChatAIManager';


export default function RightSidebar({ className }) {

  return (
    <SelectorsProvider>
      {(props) => <CustomChatAIManager {...props} />}
    </SelectorsProvider>
  );
}
