import React from 'react';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';

const CustomModal = ({ children, title, close, ...props }) => {
  return (
    <Modal onClose={close} {...props}>
      <Fade in={props.open}>
        
          <div className="flex-grow overflow-y-auto">{children}</div>
        
      </Fade>
    </Modal>
  );
};

export default CustomModal;
