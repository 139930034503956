export const selectedClasses = ["about", "testimonial", "review-quote-img", "header_inner_half", "header_inner_bg", "main_logo","header_button","header_blue_button","about_button","work_button","benefits_button","service_button","header_button_two","header_blue_button_two","about_button_two","work_button_two","benefits_button_two","service_button_two"];

export const buttonClasses = ["header_button","about_button","work_button","benefits_button","service_button"];

export const buttonClassesTwo = ["header_button_two","about_button_two","work_button_two","benefits_button_two","service_button_two"];

export const headerClasses = ["header_heading", "header_mark_icon", "about-title", "work_title", "benefits-title", "review-title", "service-title", "faq-title","header_second_inner","header_button", "about_button", "work_button", "benefits_button", "service_button","header_circle_outer_layer", "gallery_5_title", "gallery_4_title", "gallery_3_title", "gallery_1_title", "pricing_2_title", "pricing_1_title"];

export const textClasses = ["header_text","header_mark", "header_mark_text", "about-text","work-head", "work-subHead", "work_text", "benefits_heading", "benefits_text", "testimonial__text","testimonial__name", "testimonial__job", "service-text", "faq-heading", "faq-text", "copyright-text","footer-textone", "footer-texttwo","header-content","about-line", "work-line", "benefits-line", "review-line", "service-line", "faq-line", "pricing_2_data", "pricing_1_data", "td_2_head", "td_1_head"];

export const backgroundClasses = ["header_mark_icon", "header_second_inner","header_button", "about_button", "work_button", "benefits_button", "service_button", "header_circle_outer_layer" ];


export const borderClasses = ["about-line", "work-line", "benefits-line", "review-line", "service-line", "faq-line", "gallery_1_line", "gallery_3_line", "gallery_4_line", "gallery_5_line"];