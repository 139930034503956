import React from 'react';
import HeaderComp from './Header/Header';

const DomainInstructions = () => {
  return (
    <div className="scrapper">
      <div className="scrapper-inner">
        <HeaderComp />
        <div className="sm:px-[120px] sm:py-[42px] px-4 py-6">
          <div className="bg-white rounded-lg p-6">
            <h1 className="text-2xl font-bold text-center mb-8">Domain Configuration Instructions</h1>

            {/* Connect New Domain Instructions */}
            <div className="mb-10">
              <h2 className="text-xl font-bold text-blue-600 mb-4">Connect Your Domain to Joxy</h2>
              <ol className="list-decimal pl-6 mb-4 text-gray-700 space-y-2">
                <li>Log in to your domain provider and go to DNS settings.</li>
                <li>
                  Add a CNAME record for <strong>"try"</strong> pointing to: 
                  <strong> 8b23c14144364433ad4cbd5dfdaa7ffd.joxy.ai</strong>
                </li>
              </ol>
            </div>

            {/* General DNS Setup Instructions */}
            <div>
              <h2 className="text-xl font-bold text-gray-800 mb-4">Follow These Steps to Set Up DNS</h2>
              <ol className="list-decimal pl-6 text-gray-700 space-y-2">
                <li>Log in to your DNS provider's admin page.</li>
                <li>Navigate to the DNS management page for your domain.</li>
                <li>Add a new record of type "CNAME".</li>
                <li>In the "host", "name", or "subdomain" field, copy and paste the alphanumeric string provided in your email.</li>
                <li>In the "Target", "points to", or "value" field, enter: <strong>8b23c14144364433ad4cbd5dfdaa7ffd.joxy.ai</strong></li>
                <li>Click the TTL dropdown box, select Custom, and enter the value as 600.</li>
                <li>Click Save to apply the changes.</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DomainInstructions;
