import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import "../pages/HTMLPage.css";
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable, { createTheme } from 'react-data-table-component';
import HeaderComp from './Header/Header';
import {
  mdiClose,
  mdiPencilBox,
  mdiTrashCan
} from "@mdi/js";
import Icon from "@mdi/react";
import { uploadSSLCertificate, generateJoxySSL } from '../actions/scripts';
import {
  deleteDomain,
  editDomain,
  getExistingDomains
} from '../actions/grapesjs';
import { getUserSubscriptionStatus } from "../actions/auth";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

createTheme('custom', {
  text: {
    primary: '#2688D1',
    secondary: '#10176B',
  },
  background: {
    default: 'transparent',
  },
  divider: {
    default: 'rgba(0,0,0,.08)',
  },
});

const DomainList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pemFiles, setPemFiles] = useState({ cert: null, key: null });
  const [crtFiles, setCrtFiles] = useState({ cert: null, key: null, ca: null });
  const [sslOption, setSSLOption] = useState('pem');
  const [buttonAction, setButtonAction] = useState(false);
  const [userDomains, setUserDomains] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState(null); // Store selected domain
  const [editDomainName, setEditDomainName] = useState(false);
  const [editableDomain, setEditableDomain] = useState(null);

  const token = localStorage.getItem("user");
  const userData = JSON.parse(token);
  const userId = userData.user.id;

  useEffect(() => {
    dispatch(getUserSubscriptionStatus())
    .then((res) => {
      const subscription = res.data;
      console.log(subscription);
      if (subscription && new Date(subscription.endDate) > new Date() && subscription.status === "active") {

      } else {
        navigate("/subscribe", { replace: true });
      }
    })
    .catch((error) => {
      console.error(error);
    });
    dispatch(getExistingDomains(userId)).then((response) => {
      setUserDomains(response.data);
      setIsLoading(false);
    });
  }, [dispatch, userId]);

  const handleAddSSL = (domainObj) => {
    const { domain } = domainObj;

    setSelectedDomain(domain); // Set selected domain
    setIsModalOpen(true); // Open modal for SSL upload
  };

  const deleteSelectedDomain = (domainObj) => {
    Swal.fire({
      html: "<div><p style='font-size:20px; font-weight:700; margin-bottom:20px'>Are you sure?</p><span style='font-size:14px; font-weight:400; margin-bottom:32px'>You will not be able to recover this domain.</span></div>",
      customClass: {
        confirmButton: "btn-success",
        cancelButton: "btn-danger",
        closeButton: "close-button",
      },
      icon: "",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
      showLoaderOnConfirm: true,
      showCloseButton: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteDomain(domainObj.id))
          .then(async (response) => {
            dispatch(getExistingDomains(userId)).then((response) => {
              setUserDomains(response.data);
              setIsLoading(false);
            });
          })
          .catch((error) => {
            toast.error("Unable to Delete Domain");
            console.error("Error fetching data:", error);
          });
      }
    });
  };

  // Handle input change
const handleInputChange = (e) => {
  const { name, value } = e.target;
  setEditableDomain((prev) => ({
    ...prev,
    [name]: value
  }));
};

  const updateDomain = () => {
    dispatch(editDomain(editableDomain))
     .then(async (response) => {
      if(response.data.message){
        return toast.error(response.data.message);
      }
        dispatch(getExistingDomains(userId)).then((response) => {
          setUserDomains(response.data);
          setIsLoading(false);
        });
        setEditDomainName(false);
        setEditableDomain(null);
      })
     .catch((error) => {
        toast.error("Unable to Update Domain");
        console.error("Error fetching data:", error);
      });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setButtonAction(true);

    try {
      if (sslOption === 'joxy') {
        // Handle Joxy Managed SSL
        await dispatch(generateJoxySSL({ domain: selectedDomain }));
        Swal.fire('Success', 'SSL Certificates generated successfully.', 'success');
        setIsModalOpen(false);
      } else {
        // Handle PEM or CRT Upload
        const formData = new FormData();
        formData.append('domain', selectedDomain);
        formData.append('type', sslOption);

        if (sslOption === 'pem') {
          formData.append('certificate', pemFiles.cert);
          formData.append('privateKey', pemFiles.key);
        } else if (sslOption === 'crt') {
          formData.append('certificate', crtFiles.cert);
          formData.append('privateKey', crtFiles.key);
          formData.append('caBundle', crtFiles.ca);
        }

        await dispatch(uploadSSLCertificate(formData));
        Swal.fire('Success', 'SSL Certificates uploaded successfully.', 'success');
        setIsModalOpen(false);
      }
    } catch (error) {
      Swal.fire('Error', 'Failed to upload or generate SSL certificate.', 'error');
    } finally {
      setButtonAction(false);
    }
  };

  const columns = [
    {
      name: 'Domain Name',
      selector: (row) => row.domain,
      sortable: true,
      cell: (row) => (
        <div>
          <p className="text-[12px] font-bold">{row.domain}</p>
          <span className="block text-[8px] font-medium text-[#999999]">
            Last Updated: {moment(row.updatedAt).format("DD-MMM-YYYY HH:mm")}
          </span>
        </div>
      ),
    },
    {
      name: 'Mapped CNAME',
      selector: (row) => (
        // and link to the cname
        <a href={`http://${row.cname}`} target="_blank" rel="noreferrer" className='text-[#2688D1] hover:underline' style={{ textWrap: 'balance' }}>
          {row.cname || 'N/A'}
        </a>
      ),
      sortable: true,
    },
    {
      name: 'Configured Status',
      selector: (row) => (row.status === 'Configured' ? 'Configured' : 'Not Configured'),
      cell: (row) => (
        <div
          className={`py-2 px-4 border ${row.status === 'Configured'  ? 'bg-[#F2FFF7] border-[#00B448] text-[#00B448]' : 'bg-[#FFF6E8] border-[#FFCC81] text-[#FFCC81]'
            }`}
        >
          {row.status === 'Configured'  ? 'Configured' : 'Not Configured'}
        </div>
      ),
      sortable: true,
    },
    {
      name: 'SSL Action',
      cell: (row) => (
        row.status === 'Configured' ?
        !(row?.ssl_end_date) ? (
          <button
            onClick={() => handleAddSSL(row)}
            className="btn btn-outline btn-xs py-1 px-3 bg-white rounded border border-[#2688D1] text-[#2688D1]"
          >
            {'Add SSL'}
          </button>
        ) :
        moment(row.ssl_end_date).isBefore(moment()) ? (
          <button
            onClick={() => handleAddSSL(row)}
            className="btn btn-outline btn-xs py-1 px-3 bg-white rounded border border-[#2688D1] text-[#2688D1]"
          >
            {'Renew SSL'}
          </button>
        ) : 
        <div
          className={`py-2 px-4 border bg-[#F2FFF7] border-[#00B448] text-[#00B448]`}
        >
          Secured
        </div>
         : 
         <div
           className={`py-2 px-4 border bg-[#FFF6E8] border-[#FFCC81] text-[#FFCC81]`}
         >
           Unsecured
         </div>
      ),
    },
    {
      name: 'Action',
      cell: (row) => (
        <>
          {!(row.status === 'Configured') && (
            <button
              onClick={() => {
                setEditDomainName(true);
                setEditableDomain(row)
              }}
              className="btn btn-outline btn-xs py-1 px-3 bg-white rounded border border-[#2688D1] text-[#2688D1]"
            >
              <Icon size={0.9} path={mdiPencilBox} />
            </button>)} &nbsp; &nbsp;

          {!(row.status === 'Configured')  && (
            <button
              onClick={() => deleteSelectedDomain(row)}
              className="btn btn-outline btn-xs py-1 px-3 bg-white rounded border border-[#FF0000] text-[#FF0000]"
            >
              <Icon size={0.9} path={mdiTrashCan} />
            </button>
          )}
          {row.status === 'Configured'  && (
            "N/A"
          )}
        </>
      ),
    }
  ];

  return (
    <div>
      <div className="scrapper">
        <div className="scrapper-inner">
          <HeaderComp />
          <div className=" sm:px-[120px] sm:py-[42px] px-4 py-6  sm:h-[80vh] h-[74vh] overflow-y-auto">
            <div className={`bg-white rounded-lg`}>
              <div className="px-6"
                style={{
                  width: "100%",
                  height: "100%",
                  typography: "body1",
                  overflowY: "auto",
                  "&::WebkitScrollbar": {
                    display: "none",
                  },
                  scrollbarWidth: "none",
                  display: "flex",
                  flexDirection: "column",
                }}>
                <div className=" font-cera-pro-bold my-auto text-[24px]">
                  My Domains
                </div>
                <div className="domain-list-section mt-8">
                  <DataTable
                    columns={columns}
                    data={userDomains}
                    pagination
                    theme="custom"
                    progressPending={isLoading}
                    highlightOnHover
                    expandableRows
                    expandableRowsComponent={({ data }) => (
                      <div className="p-4">
                        <p className="text-sm">Additional Information</p>
                        <ul>
                          <li><strong>Domain:</strong> {data.domain}</li>
                          <li><strong>Last Updated:</strong> {moment(data.updatedAt).format("DD-MMM-YYYY HH:mm")}</li>
                          <li><strong>CName Configured:</strong> {data.status === 'Configured'  ? 'Yes' : 'No'}</li>
                          <li><strong>Mapped CNAME:</strong> <a href={`http://${data.cname}`} target="_blank" rel="noreferrer">{data.cname}</a></li>
                        </ul>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer autoClose={2000} pauseOnHover={false} theme="colored" />
      </div>

      {/* Modal for SSL Form */}
      {isModalOpen && (
        <div className="edit-modal absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-xl relative w-[600px]">
            <div className="flex justify-end p-2">
              <button
                className="outline outline-black outline-1 rounded-full"
                type="button"
                onClick={() => setIsModalOpen(false)}
              >
                <Icon size={0.9} path={mdiClose} />
              </button>
            </div>
            <div className="text-center">
              <h2 className="text-[20px] text-black font-bold">Upload SSL Certificate</h2>
              <p className="text-[15px] font-medium mt-2">
                Domain: <strong>{selectedDomain}</strong>
              </p>
            </div>
            <form className="px-5 pt-4 pb-6" onSubmit={handleFormSubmit}>
              <div className="mb-4">
                <span className="font-medium text-[15px] text-black">Choose SSL Option:</span>
                <div className="mt-2">
                  <label className="mr-4">
                    <input
                      type="radio"
                      value="pem"
                      checked={sslOption === 'pem'}
                      onChange={() => setSSLOption('pem')}
                    /> PEM (cert.pem, key.pem)
                  </label>
                  <label className="mr-4">
                    <input
                      type="radio"
                      value="crt"
                      checked={sslOption === 'crt'}
                      onChange={() => setSSLOption('crt')}
                    /> CRT/KEY (certificate.crt, private.key, ca_bundle.crt)
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="joxy"
                      checked={sslOption === 'joxy'}
                      onChange={() => setSSLOption('joxy')}
                    /> Joxy Managed SSL (Automatic generation)
                  </label>
                </div>
              </div>

              {sslOption === 'pem' && (
                <>
                  <input
                    type="file"
                    name="cert"
                    onChange={(e) => setPemFiles({ ...pemFiles, cert: e.target.files[0] })}
                    className="w-full mt-2 p-2 outline outline-1 outline-gray-400 rounded-lg"
                    placeholder="Certificate (cert.pem)"
                  />
                  <input
                    type="file"
                    name="key"
                    onChange={(e) => setPemFiles({ ...pemFiles, key: e.target.files[0] })}
                    className="w-full mt-4 p-2 outline outline-1 outline-gray-400 rounded-lg"
                    placeholder="Private Key (key.pem)"
                  />
                </>
              )}

              {sslOption === 'crt' && (
                <>
                  <input
                    type="file"
                    name="cert"
                    onChange={(e) => setCrtFiles({ ...crtFiles, cert: e.target.files[0] })}
                    className="w-full mt-2 p-2 outline outline-1 outline-gray-400 rounded-lg"
                    placeholder="Certificate (certificate.crt)"
                  />
                  <input
                    type="file"
                    name="key"
                    onChange={(e) => setCrtFiles({ ...crtFiles, key: e.target.files[0] })}
                    className="w-full mt-4 p-2 outline outline-1 outline-gray-400 rounded-lg"
                    placeholder="Private Key (private.key)"
                  />
                  <input
                    type="file"
                    name="ca"
                    onChange={(e) => setCrtFiles({ ...crtFiles, ca: e.target.files[0] })}
                    className="w-full mt-4 p-2 outline outline-1 outline-gray-400 rounded-lg"
                    placeholder="CA Bundle (ca_bundle.crt)"
                  />
                </>
              )}

              <div className="flex justify-center mt-6">
                <button
                  type="button"
                  className="rounded text-[12px] outline outline-1 outline-black text-center font-medium w-[79px] h-[27px] text-black btn-bg hover:outline-none hover:text-white"
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="ms-2 outline outline-1 outline-black rounded leading-[15.08px] text-[12px] text-center h-[27px] font-medium px-2 text-black btn-bg hover:outline-none hover:text-white"
                  disabled={buttonAction}
                >
                  {sslOption === 'joxy' ? 'Generate SSL' : 'Upload SSL'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* -------------------------------------------- Edit Modal------------------------------------------ */}

      {(editDomainName && editableDomain) && <div className=" edit-moadal absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 ">
        <div className="bg-[white] rounded-xl relative w-[484px] ">
          <div className="flex justify-end pe-2 pt-2">
            <button
              className="outline outline-black outline-1 rounded-full"
              type="button"
              onClick={() => {
                setEditDomainName(false);
                setEditableDomain(null);
              }
              }
            >
              <Icon size={0.9} path={mdiClose} color={'black'} />
            </button>
          </div>
          <div className="flex justify-center">
            <span className="text-[20px] text-black font-bold leading-6">
              Edit Domain
            </span>
          </div>
          <div className="px-5 pt-6">
            <div className="">
              <span className="font-medium text-[15px] text-black leading-5">
                Domain Name
              </span>
              <input
                type="text"
                className=" w-[100%] mt-2 outline outline-1 outline-[#959595] rounded-lg ps-[10px] py-[10px] font-medium text-[15px] text-black leading-5 placeholder:font-medium placeholder:text-[15px] placeholder:leading-5"
                name="domain"
                placeholder="try.example.com (without http:// or https://)"
                value={editableDomain.domain}
                onChange={handleInputChange}
              />
            </div>
            <div className="mt-3">
              <span className="font-medium text-[15px] text-black leading-5">
                Page path
              </span>
              <input
                type="text"
                className=" w-[100%] mt-2 outline outline-1 outline-[#959595] rounded-lg ps-[10px] py-[10px] font-medium text-[15px] text-black leading-5 placeholder:font-medium placeholder:text-[15px] placeholder:leading-5"
                name="page_path"
                placeholder="abc-ab-abc"
                value={editableDomain.page_path}
                onChange={handleInputChange}
              />
            </div>
            {/* {error && <div className="mt-1 mb-2">
              <span className="font-normal text-[14px] text-[red] leading-5">{error}</span>
            </div>} */}

            <div className="text-center mb-10 mt-6">
              <button
                type="button"
                className="rounded text-[12px] outline outline-1 outline-black text-center font-medium w-[79px] h-[27px] text-black btn-bg hover:outline-none hover:text-white"
                onClick={()=>{
                  setEditDomainName(false);
                  setEditableDomain(null);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="ms-2 rounded text-[12px] outline outline-1 outline-black text-center font-medium w-[79px] h-[27px] text-black btn-bg hover:outline-none hover:text-white"
                onClick={()=>{
                  updateDomain();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      }
      <ToastContainer autoClose={2000} pauseOnHover={false} theme="colored" />
    </div>
  );
};

export default DomainList;
