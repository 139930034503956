import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { createSessionByAdmin, saveUserDetails, getUserSubscriptionStatus } from '../../actions/auth';

const CreateSession = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        // cleat local storage
        localStorage.clear();
        dispatch(createSessionByAdmin({ id }))
            .then(() => {
                dispatch(saveUserDetails())
                dispatch(getUserSubscriptionStatus())
                .then((res) => {
                    const subscription = res.data;
                    if (
                        subscription &&
                        (!subscription.endDate ||
                        new Date(subscription.endDate) <= new Date())
                    ) {
                        console.log("here", subscription);
                        navigate("/subscribe", { replace: true });
                        return;
                    }
                    navigate('/dashboard', { replace: true });
                    return; 
                })
                .catch((error) => {
                    console.error(error);
                });
            })
            .catch((e) => {
                console.error('Error creating session:', e);
                navigate('/login', { replace: true });
            });
    }, [dispatch, navigate, id]);

    return (
        <>
            <div className={`grid`}>
                <div className={`grid__item`}>
                    <div className={`grid__item__content`}>
                        <div className={`grid__item__content__inner`}>
                            <div className={`grid__item__content__inner__text`}>
                                <h1>Creating Session...</h1>
                                {/* show message you will be redirected soon */}
                                <p>You will be redirected soon...</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateSession;