import React from "react";
import { WithEditor } from "@grapesjs/react";
import { cx } from "./common";
import TopbarButtons from "./TopbarButtons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

export default function Topbar({ className, rightToggelValue, showSidebar, editorState }) {
  const {save_template} = useSelector((state) => state.grapejs);
  const navigate = useNavigate();

  return (
    <>
    <div className={cx("bg-[#222222] flex min-h-[34px] items-center md:px-[120px]", className)}>
      <div className="font-medium text-[12px]">
        <span className="">Variant:</span><span className="px-1 py-0 ml-1">{save_template?.variant_title}</span>
        <span className="ms-4 me-4 border-[0.5px] border-end"></span>
        <span className="">Version:</span><span className="px-1 py-0 ml-1">{save_template?.variant_name}</span>
      </div>
      {/* <div className=" underline italic">{user?.setTimeDifference}</div> */}
    </div>
    <div className={cx("gjs-top-sidebar flex items-center min-h-20 px-6 py-[22px] md:px-[120px]", className)}> 
      <div
        className="grape-top-header"
        style={{ cursor: "pointer" }}
        onClick={() => {
          if (editorState) { //show message if user is editing and try to navigate to dashboard changes will be lost
            // if (!window.confirm("You have unsaved changes. Do you want to leave?", "Leave page", "Stay on page")) {
            //   return;
            // }

            Swal.fire({
              title: 'You have unsaved changes',
              text: 'Do you want to leave?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Leave page',
              cancelButtonText: 'Stay on page',
              customClass: {
                confirmButton: 'swal2-confirm custom-confirm-button',
                cancelButton: 'swal2-cancel custom-cancel-button',
                input: 'swal2-input scrapper-form-text rounded-[50px] px-6 py-[20px] border-2 border-black',
                closeButton: "close-button"
              },
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/");
                localStorage.removeItem("selectedMenuItem");
              }
            });
          }else{
            navigate("/");
            localStorage.removeItem("selectedMenuItem");
          }
        }}
      >
        <img src="/images/new_theme/Joxy.svg" className="scrapper-logo" alt="" />
      </div>
      {/* <DevicesProvider>
        {({selected, select, devices}) => (
          <FormControl size="small">
            <Select value={selected} onChange={(ev) => select(ev.target.value)}>
              {devices.map((device) => (
                <MenuItem value={device.id} key={device.id}>
                  {device.getName()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </DevicesProvider> */}
      <WithEditor>
        <TopbarButtons
          rightToggelValue={rightToggelValue}
          showSidebar={showSidebar}
          className="ml-auto px-2"
        />
      </WithEditor>
    </div>
    </>
    
  );
}
