import { ToastContainer } from "react-toastify";
import HeaderComp from "../Header/Header";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getSubscriptionStatus } from "../../actions/auth";

const PaymentMessage = () => {
  const { paymentStatus } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const getQueryParam = (param) => {
    return new URLSearchParams(location.search).get(param);
  };
  const sessionId = getQueryParam("session_id");

  useEffect(() => {
    if (sessionId) {
      dispatch(getSubscriptionStatus({sessionId}))
        .then((res) => {
          const plan_data = res.data;
          console.log({ plan_data });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);
  return (
    <>
      <div className="scrapper">
        <div className="scrapper-inner">
          <HeaderComp />
          <div className="w-[405px] m-auto pt-[32px] text-center">
            {paymentStatus === "success" ? (
              <>
                <h1 className="font-cera-pro text-[42px] font-bold leading-[52.79px] pb-3 text-center">
                  Thank You
                </h1>
                <div className="font-cera-pro text-[36px] font-bold leading-[52.79px] pb-3 text-center">
                  <p>Payment Successfull! </p>
                </div>
                <div className="mb-[24px]">
                  <span className="font-cera-pro text-[16px] leading-[24px] pb-3 text-center">
                    Your transaction has been successfully processed, and your
                    subscription is now active.
                  </span>
                </div>
                <button className="bg-[#2688D1] text-[white] py-[17px] px-[40px] text-center text-[16px] font-medium rounded-md" onClick={()=>{navigate("/", { replace: true })}}>
                  Go to Dashboard
                </button>
              </>
            ) : (
              <>
                <h1 className="font-cera-pro text-[42px] font-bold leading-[52.79px] pb-3 text-center">
                  Oops!!!
                </h1>
                <div className="font-cera-pro text-[36px] font-bold leading-[52.79px] pb-3 text-center">
                  <p>Payment Cancelled! </p>
                </div>
                <div className="mb-[24px]">
                  <span className="font-cera-pro text-[16px] leading-[24px] pb-3 text-center">
                  Your payment has been cancelled. No charges were made to your account.
                  If this was a mistake or you wish to complete the payment, please try again
                  </span>
                </div>
                <button className="bg-[#2688D1] text-[white] py-[17px] px-[40px] text-center text-[16px] font-medium rounded-md" onClick={()=>{navigate("/subscribe", { replace: true })}}>
                  Retry
                </button>
              </>
            )}
          </div>
          <ToastContainer
            autoClose={2000}
            pauseOnHover={false}
            theme="colored"
          />
        </div>
      </div>
    </>
  );
};
export default PaymentMessage;
