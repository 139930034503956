import React, { useEffect, useState } from "react";
import "../pages/HTMLPage.css";
import { useNavigate, useParams } from "react-router-dom";
import { scraper1 } from "../actions/scraper.js";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { googleLogout } from "@react-oauth/google";
import HeaderComp from "./Header/Header.jsx";
import WebsiteList from "./WebsiteList.js";
import { getUserSubscriptionStatus } from "../actions/auth";
const Swal = require("sweetalert2");

const Dashboard = () => {
  let [inputValue, setInputValue] = useState("");
  const [buttonAction, setButtonAction] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {paymentStatus} = useParams();

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [menuOpen, setMenuOpen] = React.useState(null);
  const handleMenuClick = (id) => {
    setMenuOpen(id);
  };
  const handleMenuClose = () => {
    setMenuOpen(null);
  };

  const isValidUrl = (url) => {
    try {
      //previous
      // new URL(url);
      // return true;

      //validate www
      // const urlRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;
      // return urlRegex.test(url);

      //include www
      // const urlRegex =
      //   /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\/[^\s]*)?$/;
      const urlRegex =
        /^(https?:\/\/)?((www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+)(\/[^\s]*)?$/;

      return urlRegex.test(url);
    } catch (error) {
      return false;
    }
  };

  useEffect(()=>{
    if(paymentStatus === "success"){
      Swal.fire({
          title: "Payment Success",
          imageUrl: "/images/publish.svg",
          imageWidth: 166,
          imageHeight: 162.52,
          imageAlt: "Custom image",
          customClass: {
            htmlContainer: "htmlContainer1",
            title: "custom-swal-title",
            confirmButton: "btn-gotit",
            closeButton: "close-button",
            popup: "popup",
            image: "customimg",
          },
          html: `<div style="margin-bottom:24px;"><span style="font-size:14px; font-weight:400;">Your payment has been processed successfully! </span></div>`,
          showCancelButton: false,
          confirmButtonText: "Got it",
          showCloseButton: true,
        });
    }
    if(paymentStatus === "cancel"){
      Swal.fire({
          title: "Payment Cancel",
          imageUrl: "/images/publish.svg",
          imageWidth: 166,
          imageHeight: 162.52,
          imageAlt: "Custom image",
          customClass: {
            htmlContainer: "htmlContainer1",
            title: "custom-swal-title",
            confirmButton: "btn-gotit",
            closeButton: "close-button",
            popup: "popup",
            image: "customimg",
          },
          html: `<div style="margin-bottom:24px;"><span style="font-size:14px; font-weight:400;">Your payment has been cancelled! Plz try again ! </span></div>`,
          showCancelButton: false,
          confirmButtonText: "Got it",
          showCloseButton: true,
        });
    }

    
    dispatch(getUserSubscriptionStatus())
    .then((res) => {
      const subscription = res.data;
      console.log(subscription);
      if (subscription && new Date(subscription.endDate) > new Date() && subscription.status === "active") {
        
      } else {
        navigate("/subscribe", { replace: true });
      }
    })
    .catch((error) => {
      console.error(error);
    });
  })

  const token = localStorage.getItem("user");

  // Parse the JSON string to get the object
  const userData = JSON.parse(token);

  // const handleLogout = async () => {
  //   try {
  //     if (userData?.user?.clientId) {
  //       await googleLogout();
  //       localStorage.clear();
  //       navigate("/login");
  //     } else {
  //       localStorage.clear();
  //       navigate("/login");
  //     }
  //     // Additional logout logic (clear localStorage, redirect, etc.)
  //   } catch (error) {
  //     console.error("Error during logout:", error);
  //   }
  // };

  // handleLogout();

  // Access the "id" property from the user object
  const userId = userData?.user.id;
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!isValidUrl(inputValue)) {
      // alert('Please enter a valid URL.');
      toast.error("Please enter valid URL");
      return;
    }
    if (!inputValue.includes("://")) {
      inputValue = "http://" + inputValue;
    }

    // Show the loader
    setButtonAction(true);
    if (userId) {
      try {
        dispatch(
          scraper1({
            inputField: inputValue,
            userId,
          })
        )
          .then(async (response) => {
            if (response) {
              setButtonAction(false);
              if (response.data.data) {
                navigate(`/page-processing/${response.data.data}`, {
                  state: { jsonData: response.data.data },
                });
              } else {
                Swal.fire({
                  title: "Error",
                  text: "Something went wrong. please try again!",
                  icon: "error",
                }).then((result) => {
                  if (result.isConfirmed || result.isDismissed) {
                    navigate("/");
                  }
                });
              }
            }
          })
          .catch((error) => {
            console.log(error);
            // prompt("Error! please try again!");
            Swal.fire({
              title: "Error",
              text: "Backend server offline, we are working on getting back online.",
              icon: "error",
            }).then((result) => {
              if (result.isConfirmed || result.isDismissed) {
                navigate("/");
              }
            });
          });
      } catch (error) {
        console.log("Error:", error);
      }
    }
  };
  return (
    <div>
      <div className="scrapper">
        <div className="scrapper-inner">
          <HeaderComp />
          <div
            onMouseDown={() => {
              setIsCalendarOpen(false);
              // handleMenuClose();
            }}
            className=" sm:h-[80vh] h-[74vh] overflow-y-auto"
            onScroll={() => {
              handleMenuClose();
              setIsCalendarOpen(false);
              setIsOpen(false);
            }}
          >
            <form className="form-sc" onSubmit={handleFormSubmit}>
              <div className="scrapper-form-input">
                <input
                  className="scrapper-form-text w-1/3 rounded-l-[51px] px-6 py-[20px] border-2 border-black"
                  type=""
                  id="inputtextField"
                  name="inputField"
                  placeholder="Input your url here. Test driving Joxy? Try urthbox.com or Mixpanel.com"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
                <br />
                <button
                  className={` ${
                    buttonAction
                      ? "scrapper-submit rounded-r-[51px] opacity-50 pointer-events-none cursor-none"
                      : "scrapper-submit rounded-r-[51px]"
                  }`}
                  disabled={buttonAction}
                  type="submit"
                >
                  <img
                    className="m-auto"
                    src="/images/new_theme/send.svg"
                    alt="search"
                  />
                </button>
              </div>
            </form>
            <WebsiteList
              menuOpen={menuOpen}
              handleMenuClick={handleMenuClick}
              handleMenuClose={handleMenuClose}
              isCalendarOpen={isCalendarOpen}
              setIsCalendarOpen={setIsCalendarOpen}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          </div>
        </div>
        <ToastContainer autoClose={2000} pauseOnHover={false} theme="colored" />
      </div>
    </div>
  );
};

export default Dashboard;
