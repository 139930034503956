import React, { useState, useEffect, useRef } from "react";
import {
  Box,
} from "@mui/material";
import "../../pages/chat.css"; // Import your CSS file
import {
  dummyChat2,
  replaceImagesWithThumbnails,
} from "../constants/jsonData";
import {LoaderJoxy} from "./LoaderJoxy";

export default function CustomChatBot({ scrapData, handleStepIndex }) {
  // const [currentStep, setCurrentStep] = useState(0);
  const elementsRef = useRef([]);
  const [changeStep, setChangeStep] = useState(false);

  const [chatInputValue, setChatInputValue] = useState("");
  const [showChatBox, setShowChatBox] = useState(true);
  const [scrapperData, setScrapperData] = useState(null);
  const [chatCount, setChatCount] = useState(0);

  const [minimized, setMinimized] = useState(false);
  const [visibility, setVisibility] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [changeStepData, setChangeStepData] = useState(false);
  const timeoutExec = useRef(false);
  const timeoutSet = useRef(null);
  const loaderRef = useRef(false);

  const handleStepIndexProp = (index) => {
    handleStepIndex(index);
  };

  useEffect(() => {
    if (scrapData && scrapData?.scrapingProcessingStatus) {
      setScrapperData(scrapData);
      const stepString = scrapData?.scrapingProcessingStatus.replace(
        "step_",
        ""
      );
      const step = parseInt(stepString);
      setChatCount(step);
    }
  }, [scrapData]);

  useEffect(() => {
    if (!scrapperData || !elementsRef.current.length) return;

    const handleAnimation = (el, index, steps) => {
      const width = `${steps + 1}ch`; // Adjust for padding/margin
      el.style.setProperty("--delay", `0s`);
      el.style.setProperty("--width", width);
      el.style.setProperty("--steps", steps);
      el.style.setProperty("--duration", "3s");

      el.classList.add("typing");
      setLoading(false);
      setTimeout(() => {
        el.classList.remove("typing");
        el.classList.add("done");
        setChangeStep(true);
        setLoading(true);
      }, 3 * 1000); // Delay + duration in ms
    };

    elementsRef.current.forEach((el, index) => {
      if (el && !el.classList.contains("done") && !el.classList.contains("typing")) {
        const dataText = el.getAttribute("data-text");
        if (dataText) {
          const steps = dataText.length;
          handleAnimation(el, index, steps);
        }
      }
    });
  }, [scrapperData, changeStep, currentStep, changeStepData]);

  useEffect(() => {
    if (!scrapperData) return;
    const currentStepMax = { 1: 2, 2: 6, 3: 8, 4: 14, 5: 15};
    var stepCount = scrapperData
      ? parseInt(scrapperData?.scrapingProcessingStatus.replace("step_", ""))
      : 1;
    if (currentStep >= 14 &&
      scrapperData?.generated_web_screenshot
    ) {
      setLoading(false);
    }
    if (currentStep < currentStepMax[stepCount] && changeStep) {
      handleStepIndexProp(currentStep);
      loaderRef.current = false;
      let timeout = 3000;
      if (currentStep <= 1) {
        setChangeStepData(true);
        if (currentStep === 1) {
          loaderRef.current = true;
        }
      }

      if (
        currentStep >= 2 &&
        currentStep <= 5 &&
        scrapperData?.websiteScreenshot
      ) {
        setChangeStepData(true);
        if (currentStep === 5) {
          loaderRef.current = true;
        }
      }

      if (
        currentStep >= 6 &&
        currentStep <= 7 &&
        scrapperData?.scrapperColors
      ) {
        setChangeStepData(true);
        if (currentStep === 7) {
          loaderRef.current = true;
        }
      }

      if (currentStep >= 8 && currentStep <= 13 && scrapperData?.websiteLogo) {
        setChangeStepData(true);
        if (currentStep === 13) {
          loaderRef.current = true;
        }
      }

      if (currentStep >= 14 && !scrapperData.generated_web_screenshot) {
        setChangeStepData(true);
        loaderRef.current = true;
      }
      // setCurrentStep((prevStep) => prevStep + 1);
      // setChangeStep(false);
      setLoading(loaderRef.current);
      timeoutExec.current = false;

      timeoutSet.current = setTimeout(() => {
        if (changeStepData) {
          setCurrentStep((prevStep) => prevStep + 1);
        } else {
          setLoading(true);
        }

        setChangeStep(false);
        setLoading(loaderRef.current);
        timeoutExec.current = true;
      }, timeout); // 1000ms delay, adjust as needed

      console.log("timeout", timeout);

    }
    return () => {
      if (timeoutSet.current && timeoutExec.current) {
        clearTimeout(timeoutSet.current);
      }
    };
  }, [currentStep, scrapperData, changeStep, changeStepData]);


  const handleVariantPage = (websiteId, variant_id) => {
    // Construct the URL for the new tab
    const newTabUrl = `/web-builder/${websiteId}?v_id=${variant_id}`;

    // Open the URL in a new tab
    window.open(newTabUrl, "_blank");
  };

  const toggleChatbox = () => {
    setVisibility((prevVisibility) => !prevVisibility);
    setMinimized(false);
    setShowChatBox(false);
  };

  const openChatboxToggle = () => {
    setVisibility((prevVisibility) => !prevVisibility);
    setMinimized(false);
    setShowChatBox(true);
  };

  return (
    <>
      {visibility ? (
        <div className={`chatbox  `}>
          <>
            <div className="chatboxSection">
              {showChatBox && (
                <div
                  style={{
                    boxShadow: "-4px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                  }}
                  className={`md:mr-[120px] fixed bottom-[calc(2rem+8rem)] md:bottom-[calc(2rem+1.5rem)] right-0 mr-4 bg-white px-4 py-6 rounded-2xl  w-[350px] md:w-[469px]  ${minimized ? "h-[70px]" : "lg:h-[574px] md:h-[465px] h-[574px]"
                    }`}
                >
                  <div
                    className={`flex flex-col space-y-1.5  ${minimized ? "pb-0" : "pb-[18px]"
                      }`}
                  >
                    <div
                      className={`chat-header ${minimized ? "" : "pb-4 border-b border-[#CECECE]"
                        }`}
                    >
                      {" "}
                      <div className="flex">
                        {" "}
                        <img
                          src="/images/new_theme/Joxy_favicon.svg"
                          className=""
                          alt=""
                        />
                        <div className="chat-header-text">Copilot</div>
                      </div>
                      {/* <div className="sideIcons">
                        {!minimized && (
                          <>
                            <button className="minimize-button">
                              <Icon
                                onClick={toggleMinimized}
                                size={1.0}
                                path={mdiMinus}
                                color="black"
                              />
                            </button>
                          </>
                        )}
                        {minimized && (
                          <button className="minimize-button">
                            <Icon
                              onClick={toggleMinimized}
                              size={1.0}
                              path={mdiWindowMaximize}
                              color="black"
                            />
                          </button>
                        )}

                        <Icon
                          onClick={toggleChatbox}
                          size={1.0}
                          path={mdiClose}
                          color="black"
                        />
                      </div> */}
                    </div>
                  </div>
                  {!minimized && (
                    <>
                      <div className="pr-4 h-[400px] min-w-full overflow-x-auto max-h-full flex flex-col-reverse">
                        <div className="">
                          {" "}
                          {scrapperData ? (
                            <>
                              {dummyChat2.map(
                                (data, index) =>
                                  scrapperData &&
                                  currentStep >= index && (
                                    <React.Fragment key={index}>

                                      <div className="">
                                        {data.type === "text" &&
                                          data.step <= chatCount && (
                                            <div className="w-auto flex gap-3 my-1.5 flex-1 float-left clear-both">
                                              <p
                                                ref={(el) =>
                                                (elementsRef.current[index] =
                                                  el)
                                                }
                                                data-text={data.message}
                                                className="text-xs text-black rounded-lg py-4 px-3 anim-typewriternew"
                                              >
                                                {data.message}
                                              </p>
                                            </div>)
                                        }
                                        {data.type === "screenshot" &&
                                          data.step <= chatCount && (
                                            <div className="flex gap-3 cursor-pointer my-1.5 flex-1 float-left clear-both w-6/12 ">
                                              <a href={scrapperData.websiteScreenshot} target="_blank" rel="noreferrer">
                                                <img
                                                  ref={(el) =>
                                                  (elementsRef.current[index] =
                                                    el)
                                                  }
                                                  className="object-contain anim-typewriternew border"
                                                  src={replaceImagesWithThumbnails(
                                                    scrapperData.websiteScreenshot
                                                  )}
                                                  alt=""
                                                  data-text={replaceImagesWithThumbnails(
                                                    scrapperData.websiteScreenshot
                                                  )}
                                                />
                                              </a>
                                            </div>
                                          )}
                                        {data.type === "colors" &&
                                          data.step <= chatCount && (
                                            <>
                                              {" "}
                                              <div
                                                className="flex gap-3 my-1.5 text-sm flex-1 float-left clear-both chat-bg-white anim-typewriternew"
                                                ref={(el) =>
                                                (elementsRef.current[index] =
                                                  el)
                                                }
                                                data-text="select color for template which implemented on selected template"
                                              >
                                                <ul className="ul_img">
                                                  {scrapperData?.scrapperColors &&
                                                    Object.entries(
                                                      scrapperData.scrapperColors
                                                    ).map(
                                                      (
                                                        [key, colorData],
                                                        idx
                                                      ) => (
                                                        <li
                                                          className="li_img"
                                                          key={idx}
                                                        >
                                                          <label
                                                            className="block px-0.5 py-1"
                                                            htmlFor={`myRadio${idx + 1
                                                              } `}
                                                          >
                                                            <div
                                                              className="w-[75px] h-[76px] border"
                                                              style={{
                                                                backgroundColor:
                                                                  colorData.color,
                                                              }}
                                                            ></div>
                                                          </label>
                                                        </li>
                                                      )
                                                      // )
                                                    )}
                                                </ul>
                                              </div>
                                            </>
                                          )}
                                        {data.type === "logoImage" &&
                                          data.step <= chatCount && (
                                            <>
                                              {" "}
                                              <div
                                                className="cursor-pointer flex gap-3 my-1.5 text-sm flex-1 float-left clear-both logo-trans w-[150px] h-[150px] chat-bg-white"
                                              >
                                                <a href={scrapperData.websiteLogo} target="_blank" rel="noreferrer" className="chat-bg-white anim-typewriternew" 
                                                    ref={(el) =>
                                                    (elementsRef.current[index] =
                                                      el)
                                                    }
                                                    data-text="select logo image for template section">
                                                  <img
                                                    className="object-contain p-0.5 border border-[#D9D9D9] w-[150px] h-[150px]"
                                                    src={replaceImagesWithThumbnails(
                                                      scrapperData.websiteLogo
                                                    )}
                                                    alt=""
                                                  />
                                                </a>
                                              </div>
                                            </>
                                          )}
                                        {data.type === "variants" && scrapperData?.generated_web_screenshot &&
                                          data.step <= chatCount && (
                                            <>
                                              {" "}
                                              <div
                                                className="flex gap-3 my-1.5 text-sm flex-1 float-left clear-both chat-bg-white anim-typewriternew"
                                                ref={(el) =>
                                                (elementsRef.current[index] =
                                                  el)
                                                }
                                                data-text="select logo image for template section"
                                              >
                                                <ul className="ul_img">
                                                  {Object.entries(
                                                    scrapperData.varaint_data
                                                  ).map(
                                                    ([key, variant], idx) =>
                                                      variant.generated_web_screenshot && (
                                                        <li
                                                          className="li_img"
                                                          key={idx}
                                                        >
                                                          <label
                                                            className="block cursor-pointer px-0.5 py-1"
                                                            htmlFor={`myRadio${idx + 1
                                                              } `}
                                                            onClick={() => {
                                                              handleVariantPage(
                                                                variant.websiteId,
                                                                variant.id
                                                              );
                                                            }}
                                                          >
                                                            <img className="w-[200px] h-[500px] border object-contain" src={replaceImagesWithThumbnails(variant.generated_web_screenshot)} />
                                                          </label>
                                                        </li>
                                                      )
                                                  )}
                                                </ul>
                                              </div>
                                            </>
                                          )}

                                        {!scrapperData && (
                                          <LoaderJoxy />
                                        )}
                                      </div>
                                    </React.Fragment>
                                  )
                              )}

                              {loading && <LoaderJoxy />}
                            </>
                          ) : (
                            <div className="flex gap-3 my-1.5 text-sm flex-1 float-left clear-both w-[70.5%]">
                              <div className="scrapper-gif-image">
                                <img
                                  src="/images/new_theme/Finalize-Loading-Animation.gif"
                                  className="h-12"
                                  alt="animation loader"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="flex items-center pt-2.5">
                        <form
                          className="flex items-center justify-center w-full"
                          onSubmit={(event) => event.preventDefault()}
                        >
                          <textarea
                            className="flex resize-none h-[44px] w-full rounded-l-2xl border border-r-0  border-[#e5e7eb] px-3 py-2 text-sm placeholder-[#6b7280] focus:outline-none  text-[#030712] focus-visible:ring-offset-2"
                            type="textarea"
                            id="chatInputField"
                            name="chatInputField"
                            placeholder="Type your message"
                            value={chatInputValue}
                            onChange={(e) => setChatInputValue(e.target.value)}
                            readOnly={true}
                          />
                          <div className="inline-flex items-center justify-center rounded-r-2xl text-sm font-medium text-[#f9fafb] border border-l-0 border-[#e5e7eb] h-[44px] w-[64px] py-2 px-[15px]">
                            <img
                              src="/images/new_theme/chatButton.svg"
                              className="w-[28px] h-[28px] opacity-75"
                              alt=""
                            />
                          </div>
                        </form>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </>
        </div>
      ) : (
        <div
          className="chat-icon-outer new-icon bottom-[calc(2rem+1.7rem)] mr-[120px]"
          onClick={openChatboxToggle}
        >
          <Box>
            <img
              className=""
              src="/images/new_theme/message-question.svg"
              alt="message-question"
            />
          </Box>
        </div>
      )}
    </>
  );
}
