import React, { useState, useEffect } from "react";
import basestyle from "../Base.module.css";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resetPassword } from "../../actions/auth";

const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [user, setUserDetails] = useState({
    confirmPassword: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);


  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...user,
      [name]: value,
    });
  };
  const validateForm = (values) => {
    const error = {};
    if (values.confirmPassword !== values.password) {
      error.confirmPassword = "Please enter the same password";
    }
    if (!values.password) {
      error.password = "Password is required";
    }
    if (!values.confirmPassword) {
      error.confirmPassword = "confirm Password is required";
    }
    return error;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setFormErrors(validateForm(user));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      dispatch(resetPassword(user.password, token))
        .then(async (response) => {
          // toast.success('Password reset');
          navigate("/login", { replace: true });
        })
        .catch((error) => {
          toast.error(error.message);
          // Handle error as needed
        });
    }
  }, [formErrors]);

  return (
    <>
      <div className={` ${basestyle.formBackground} grid`}>
      <div className="xl:m-0  m-auto">
        <div className="mb-7">
          <img
            src="/images/new_theme/Joxy.svg"
            className="scrapper-logo-color"
            alt="joxy"
          />
        </div>
        <form className={basestyle.userForm}>
          <h1 className="font-cera-pro-bold text-xl font-bold leading-[33.6px] pb-7 text-center">
            Forgot Password?
          </h1>
          <p className="text-xs font-normal leading-4 mb-6  mx-auto xl:w-[287px] text-center">
            Enter the email address or mobile number you used when you joined
            and we will send you temporary password.
          </p>
          <div class="mb-3 w-full">
          <label
              for="password"
              class="font-cera-pro-bold block text-sm font-medium leading-6 mb-2"
            >
              Password
            </label>
            <div class={`relative ${basestyle.formInputBox}  w-full`}>
              <div className="inset-y-0 left-0 pr-3 flex items-center text-sm leading-5">
                <img
                  className={`h-6 text-gray-700 cursor-pointer`}
                  src="/images/new_theme/lock_icon.svg"
                  alt="lock"
                />
                 <input
                type={showPassword ? "password" : "text"}
                className="w-full pl-[7.18px] focus-visible:outline-none"
                name="password"
                id="password"
                placeholder="................"
                onChange={changeHandler}
                value={user.password}
              />
              </div>
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                <img
                  className={`h-6 text-gray-700 cursor-pointer ${
                    showPassword ? "hidden" : "block"
                  }`}
                  onClick={togglePasswordVisibility}
                  src="/images/new_theme/eye.svg"
                  alt="eye"
                />

                <img
                  className={`h-6 text-gray-700 cursor-pointer ${
                    showPassword ? "block" : "hidden"
                  }`}
                  fill="none"
                  onClick={togglePasswordVisibility}
                  src="/images/new_theme/eye-off.svg"
                  alt="eye-off"
                />
              </div>
            </div>

            <p className={basestyle.error}>{formErrors.password}</p>
          </div>
          <div class="mb-6 w-full">
          <label
              for="confirmPassword"
              class="font-cera-pro-bold block text-sm font-medium leading-6 mb-2"
            >
            Confirm Password
            </label>
            <div class={`relative ${basestyle.formInputBox}  w-full`}>
              <div className="inset-y-0 left-0 pr-3 flex items-center text-sm leading-5">
                <img
                  className={`h-6 text-gray-700 cursor-pointer`}
                  src="/images/new_theme/lock_icon.svg"
                  alt="lock"
                />
                 <input
                type={showConfirmPassword ?  "password" : "text"}
                className="w-full pl-[7.18px] focus-visible:outline-none"
                name="confirmPassword"
                id="confirmPassword"
                placeholder="................"
                onChange={changeHandler}
                value={user.confirmPassword}
              />
              </div>
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                <img
                  className={`h-6 text-gray-700 cursor-pointer ${
                    showConfirmPassword ? "hidden" : "block"
                  }`}
                  onClick={toggleConfirmPasswordVisibility}
                  src="/images/new_theme/eye.svg"
                  alt="eye"
                />

                <img
                  className={`h-6 text-gray-700 cursor-pointer ${
                    showConfirmPassword ? "block" : "hidden"
                  }`}
                  fill="none"
                  onClick={toggleConfirmPasswordVisibility}
                  src="/images/new_theme/eye-off.svg"
                  alt="eye-off"
                />
              </div>
            </div>

            <p className={basestyle.error}>{formErrors.confirmPassword}</p>
          </div>

          <button
            className={`font-cera-pro-bold w-full ${basestyle.button_common}`}
            onClick={submitHandler}
          >
            Submit
          </button>
          <div className="mt-6 text-center text-black">
          Return to{" "}
            <a href="/login" className="text-[#2688D1] underline">
            Login
            </a>
          </div>
        </form>
        <ToastContainer autoClose={2000} pauseOnHover={false} theme="colored" />
        </div>
      </div>
    </>
  );
};
export default ChangePassword;
