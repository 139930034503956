import axios from 'axios';
import { refreshTokenHit } from '../actions/auth'; // Adjust the path to your action file
const {authApi} = require('./AxiosInstance')
const API_URL = process.env.REACT_APP_API_URL;

const login = (userData) => {
  return axios.post(`${API_URL}auth/login`, {
    email: userData.email,
    password: userData.password,
    rememberMe: userData?.rememberMe
  });
};

const saveTokenInLocalStorage = (tokenDetails, date) => {
  tokenDetails.expireDate = new Date(date);
  // localStorage.setItem('expireDate', JSON.stringify(date));
  // localStorage.setItem('refreshTokenExpireDate', JSON.stringify(moment(tokenDetails.tokens.refresh.expires).valueOf()));
  localStorage.setItem('token', JSON.stringify(tokenDetails.tokens.access.token));
  localStorage.setItem('refreshToken', JSON.stringify(tokenDetails.tokens.refresh.token));
};

let logoutTimerId;
const runLogoutTimer = (dispatch, timer) => {
  if (logoutTimerId) {
    clearTimeout(logoutTimerId);
  }

  // Set a new timeout
  logoutTimerId = setTimeout(() => {
    dispatch(refreshTokenHit({ refreshToken: JSON.parse(localStorage.getItem('refreshToken')) }));
  }, timer ); // Adjusted to refresh 5 minutes before expiry
};

const refreshTokenApi = (refreshToken) => {
  return axios.post(`${API_URL}auth/refresh-tokens`, { refreshToken });
};

const register = (userData) => {
  return axios.post(`${API_URL}auth/register`, {
    name: userData.name,
    email: userData.email,
    password: userData.password,
  });
};

const googleLogin = (data) => {
  return axios.post(`${API_URL}auth/googleLogin`, {
    token: data.credential,
    clientId: data.clientId,
  });
};

const userDetails = (refreshToken) => {
  return authApi.post('/users/load/user', { refreshToken });
};

const logoutUser = (refreshToken) => {
  return axios.post(`${API_URL}auth/logout`, { refreshToken });
};

export function checkAutoLogin(dispatch, navigate) {
  const tokenDetailsString = localStorage.getItem('expireDate');
  if (!tokenDetailsString) {
    return;
  }

  const expireDate = JSON.parse(tokenDetailsString);
  const todaysDate = new Date().getTime();

  if (todaysDate > expireDate) {
    const refreshTokenExpireDate = JSON.parse(localStorage.getItem('refreshTokenExpireDate'));
    if (!refreshTokenExpireDate || new Date(refreshTokenExpireDate) < todaysDate) {
      return;
    }

    return dispatch(refreshTokenHit({ refreshToken: JSON.parse(localStorage.getItem('refreshToken')) }));
  }

  const timer = expireDate - todaysDate;
  runLogoutTimer(dispatch, timer);
}

const forgetPassword = (email) => {
  return axios.post(API_URL + "auth/forgot-password", {
      email,
  });
};
  
const resetPassword = (password, token) => {
  return axios.post(`${API_URL}auth/reset-password?token=${token}`, {
      password,
  });
};


const getUserSubscriptionStatus = () => {
  return authApi.get(API_URL + "subscription/current");
};

const subscribeToPlan = (data) => {
  return authApi.post(API_URL + "subscription/subscribe",  data);
};

const getSubscriptionPlanDetails = () => {
  return authApi.get(API_URL + "subscription/get/plans");
};

const getSubscriptionStatus = (data) => {
  return authApi.post(API_URL + "subscription/status", data);
};

const createSessionByAdmin = (data) => {
  return axios.post(API_URL + "auth/byadmin/createSession", data);
}

const getUsersEmail = (data) => {
  return authApi.post(API_URL + "users/getUsersEmail", data);
}


  const AuthService = {
    login,
    register,
    googleLogin,
    saveTokenInLocalStorage,
    runLogoutTimer,
    refreshTokenApi,
    userDetails,
    logoutUser,
    forgetPassword,
    resetPassword,
    getUserSubscriptionStatus,
    subscribeToPlan,
    getSubscriptionPlanDetails,
    getSubscriptionStatus,
    createSessionByAdmin,
    getUsersEmail
  }
  
  export default AuthService;

