import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import "../pages/HTMLPage.css";
import HeaderComp from './Header/Header';  
import Swal from 'sweetalert2';  
import { ToastContainer, toast } from 'react-toastify';  
import 'react-toastify/dist/ReactToastify.css';
import { uploadSSLCertificate, generateJoxySSL } from '../actions/scripts'; // New action for certbot
import { getExistingDomains } from '../actions/grapesjs';

const SSLCertificateUpload = () => {
  const dispatch = useDispatch();
  const [domain, setDomain] = useState('');
  const [pemFiles, setPemFiles] = useState({ cert: null, key: null });
  const [crtFiles, setCrtFiles] = useState({ cert: null, key: null, ca: null });
  const [sslOption, setSSLOption] = useState('pem'); // Track selected SSL option
  const [buttonAction, setButtonAction] = useState(false);
  const [savedDomains, setSavedDomains] = useState([]);
  const [userDomains, setUserDomains] = useState([]);

  const token = localStorage.getItem("user");
  const userData = JSON.parse(token);
  const userId = userData.user.id;

  useEffect(() => {
    dispatch(getExistingDomains(userId)).then((response) => {
      let domains = response.data;
      setUserDomains(domains);
      domains = domains.map((domain) => domain.domain);
      setSavedDomains(domains);
    });
  }, [dispatch, userId]);

  const handlePemChange = (e) => {
    setPemFiles({
      ...pemFiles,
      [e.target.name]: e.target.files[0],
    });
  };

  const handleCrtChange = (e) => {
    setCrtFiles({
      ...crtFiles,
      [e.target.name]: e.target.files[0],
    });
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    
    if (!domain) {
      toast.error('Please select a domain');
      return;
    }
  
    setButtonAction(true);
  
    // Handle Joxy Managed SSL
    if (sslOption === 'joxy') {
      try {
        // get the domain from userDomains
        const domainObj = userDomains.find((d) => d.domain === domain);
        // split folder name from cname
        const cnameFolderName = domainObj.cname.split('.')[0];

        await dispatch(generateJoxySSL({ domain, cnameFolderName }));
        Swal.fire('Success', 'SSL Certificates generated successfully.', 'success');
      } catch (error) {
        console.error('Certbot Error:', error.response ? error.response.data : error.message);
        Swal.fire('Error', 'Failed to generate SSL certificates.', 'error');
      } finally {
        setButtonAction(false);  
      }
      return;
    }

    // Handle PEM or CRT Upload
    const formData = new FormData();
    formData.append('domain', domain);
    formData.append('type', sslOption);
  
    if (sslOption === 'pem') {
      if (!pemFiles.cert || !pemFiles.key) {
        toast.error('Please upload both PEM files.');
        setButtonAction(false);
        return;
      }
      formData.append('certificate', pemFiles.cert);
      formData.append('privateKey', pemFiles.key);
    } else if (sslOption === 'crt') {
      if (!crtFiles.cert || !crtFiles.key || !crtFiles.ca) {
        toast.error('Please upload all CRT files.');
        setButtonAction(false);
        return;
      }
      formData.append('certificate', crtFiles.cert);
      formData.append('privateKey', crtFiles.key);
      formData.append('caBundle', crtFiles.ca);
    }
  
    try {
      await dispatch(uploadSSLCertificate(formData));
      Swal.fire('Success', 'SSL Certificates uploaded successfully.', 'success');
    } catch (error) {
      console.error('Upload Error:', error.response ? error.response.data : error.message);
      Swal.fire('Error', 'Failed to upload certificates.', 'error');
    } finally {
      setButtonAction(false);  
    }
  };

  return (
    <div>
      <div className="scrapper">
        <div className="scrapper-inner">
      <HeaderComp />  
      <div className="ssl-upload-content">
        <div className="ssl-upload-center">
          <h1 className="text-2xl font-bold text-center mb-8">Upload SSL Certificates</h1>
          <form className="ssl-form" onSubmit={handleUpload}>
            <select
              className="ssl-form-select mb-4"
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
            >
              <option value="">-- Select Domain --</option>
              {savedDomains.map((savedDomain) => (
                <option key={savedDomain} value={savedDomain}>
                  {savedDomain}
                </option>
              ))}
            </select>

            <div className="ssl-upload-options mb-4">
              <label className="ssl-upload-option">
                <input
                  type="radio"
                  value="pem"
                  checked={sslOption === 'pem'}
                  onChange={() => setSSLOption('pem')}
                /> PEM Upload (cert.pem, key.pem)
              </label>
              <label className="ssl-upload-option">
                <input
                  type="radio"
                  value="crt"
                  checked={sslOption === 'crt'}
                  onChange={() => setSSLOption('crt')}
                /> CRT/KEY Upload (certificate.crt, private.key, ca_bundle.crt)
              </label>
              <label className="ssl-upload-option">
                <input
                  type="radio"
                  value="joxy"
                  checked={sslOption === 'joxy'}
                  onChange={() => setSSLOption('joxy')}
                /> Joxy Managed SSL (Automatic generation)
              </label>
            </div>

            {/* Show file inputs only for PEM or CRT */}
            {sslOption !== 'joxy' && (
              <div className="ssl-file-inputs">
                {sslOption === 'pem' ? (
                  <>
                    <label htmlFor="pemCert">Certificate (cert.pem):</label>
                    <input
                      type="file"
                      name="cert"
                      id="pemCert"
                      accept=".pem"
                      onChange={handlePemChange}
                      className="ssl-file-input mb-4"
                    />
                    <label htmlFor="pemKey">Private Key (key.pem):</label>
                    <input
                      type="file"
                      name="key"
                      id="pemKey"
                      accept=".pem"
                      onChange={handlePemChange}
                      className="ssl-file-input mb-4"
                    />
                  </>
                ) : (
                  <>
                    <label htmlFor="crtCert">Certificate (certificate.crt):</label>
                    <input
                      type="file"
                      name="cert"
                      id="crtCert"
                      accept=".crt"
                      onChange={handleCrtChange}
                      className="ssl-file-input mb-4"
                    />
                    <label htmlFor="crtKey">Private Key (private.key):</label>
                    <input
                      type="file"
                      name="key"
                      id="crtKey"
                      accept=".key"
                      onChange={handleCrtChange}
                      className="ssl-file-input mb-4"
                    />
                    <label htmlFor="crtCa">CA Bundle (ca_bundle.crt):</label>
                    <input
                      type="file"
                      name="ca"
                      id="crtCa"
                      accept=".crt"
                      onChange={handleCrtChange}
                      className="ssl-file-input mb-4"
                    />
                  </>
                )}
              </div>
            )}

            <button
              type="submit"
              className="button result-button"
              disabled={buttonAction}
            >
              {sslOption === 'joxy' ? 'Generate SSL' : 'Upload SSL'}
            </button>
          </form>
        </div>
      </div>
        </div>
        <ToastContainer autoClose={2000} pauseOnHover={false} theme="colored" />
      </div>
    </div>
  );
};

export default SSLCertificateUpload;
