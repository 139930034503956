import React from 'react';
import HeaderComp from './Header/Header'; // Assuming you want to reuse the header component
import "../pages/HTMLPage.css"; // Use the same CSS as Dashboard for consistent styling

const NoMobile = () => {
  return (
    <div className="scrapper">
      <div className="scrapper-inner">
        <HeaderComp />
        <div className="sm:h-[80vh] h-[74vh] overflow-y-auto flex items-center justify-center">
          <div className="text-center">
            <h1 className="text-2xl font-bold mb-4">Not Available on Mobile</h1>
            <p className="text-lg mb-8">
              This page is not available on mobile devices. Please access it from a desktop browser.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoMobile;
