// Import necessary modules
import React from 'react';
import { createRoot }  from 'react-dom/client'; 
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AppStateProvider } from './AppStateContext';
import LogRocket from 'logrocket';

const APP_PROD = process.env.REACT_APP_PROD && process.env.REACT_APP_PROD === true ? process.env.REACT_APP_PROD : false;

// Use createRoot instead of ReactDOM.render
const root = createRoot(document.getElementById('root'));

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

if (APP_PROD) {
  LogRocket.init('c2rls1/joxyai');
}

// Render the app inside the root
root.render(
  <React.StrictMode>
        <AppStateProvider>
    <Router>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
        <GoogleOAuthProvider clientId="764801173018-ot9mibplg57a9ct631n6sn3ejum05a3n.apps.googleusercontent.com">
            <App/></GoogleOAuthProvider>
        </ThemeProvider>
      </Provider>
    </Router>
    </AppStateProvider>
  </React.StrictMode>
);

// Report web vitals
reportWebVitals();
