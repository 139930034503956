import { ToastContainer } from "react-toastify";
import basestyle from "../Base.module.css";
import { useDispatch } from "react-redux";
import {
  getSubscriptionPlanDetails,
  getUserSubscriptionStatus,
  subscribeToPlan,
} from "../../actions/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderComp from "../Header/Header";
import { ButtonLoaderJoxy } from "../library/LoaderJoxy";

const Subscribe = () => {
  const [plans, setPlans] = useState([]);
  const [isSubscribing, setIsSubscribing] = useState(null);
  const planDetails = [
    "$250 covers complete website design",
    "Expert call to make any changes you want, including design or ad copy",
    "Everything you need is included",
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subscribe = async (plan) => {
    setIsSubscribing(plan.id);
    dispatch(subscribeToPlan({
      subscriptionPlanId: plan.id,
      planType: plan.value,
    }))
      .then((res) => {
        const subscription = res.data;
        setIsSubscribing(null);
        console.log({ subscription });
        window.location.href = subscription;
      })
      .catch((error) => {
        setIsSubscribing(null);
        console.error(error);
      });
  };

  useEffect(() => {
    dispatch(getUserSubscriptionStatus())
      .then((res) => {
        const subscription = res.data;
        if (subscription && new Date(subscription.endDate) > new Date()) {
          navigate("/", { replace: true });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  });

  useEffect(() => {
    dispatch(getSubscriptionPlanDetails())
      .then((res) => {
        const plan_data = res.data;
        setPlans(plan_data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  return (
    <>
      <div className="scrapper">
        <div className="scrapper-inner">
          <HeaderComp />
          <div className="xl:m-0  m-auto">
            <div className="flex w-[80%] m-auto pt-[35px]">
              {plans.map((plan, index) => (
                <div className={basestyle.userForm} key={index}>
                  <h1 className="font-cera-pro-bold text-xl font-extrabold leading-[33.6px] pb-3 text-center">
                    Subscribe to the {plan.name}
                  </h1>
                  <h1 className="text-[18px] leading-[33.6px] pb-3 text-center">
                    $250 one-time design fee <br />
                    ${plan.amount}{" "}
                    <span className="text-[16px]">/ {plan.value} thereafter</span>
                  </h1>
                  <ul className="list-disc ps-[20px]">
                    {planDetails.map((detail, index) => (
                      <li className="text-sm font-normal leading-4 mb-3  mx-auto xl:w-[287px]">
                        {detail}
                      </li>
                    ))}
                  </ul>{" "}
                  <div className="text-center  mt-6 ">
                  {isSubscribing=== plan.id ? (
                    
                    <button
                      className={`font-cera-pro-bold w-full ${basestyle.button_common_subscribe}`}
                    >
                      <ButtonLoaderJoxy />
                    </button>
                  ) : (
                    <button
                      className={`font-cera-pro-bold w-full ${basestyle.button_common_subscribe}`}
                      onClick={()=>{subscribe(plan)}}
                    >
                      Yes, I want my high-converting landing page 
                    </button>
                  
                  )}
                    </div>
                  <div className="my-3 text-sm ps-[20px]"><span className="font-bold">30-day guarantee:</span> Not satisfied? Get a full refund!</div>
                </div>
              ))}
            </div>
            <ToastContainer
              autoClose={2000}
              pauseOnHover={false}
              theme="colored"
            />
          </div>
        </div>
      </div>
      {/* <Footer/> */}
    </>
  );
};
export default Subscribe;
